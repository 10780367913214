import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import orderService from "../../services/order";
import { useSnackbar } from "notistack";
import {
  cepFormatter,
  cnpjFormatter,
  cpfFormatter,
  getErrorMessage,
  moneyFormatter,
  phoneFormatter,
  rgFormatter,
} from "../../utils";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fab,
  Grid,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Download, UploadFile } from "@mui/icons-material";
import logo from "../../assets/images/vetline.png";

const OrderDetailsView = () => {
  const { id } = useParams();
  const [data, setData] = useState({
    products: [],
    reference: "",
    status: "",
    dateCreated: "",
    dateUpdated: "",
    paymentMethod: "",
    installment: null,
    amount: 0,
    zipCode: "",
    street: "",
    number: "",
    district: "",
    city: "",
    state: "",
    complement: "",
    nfes: [],
    user: {
      isPJ: true,
      cnpj_cpf: "",
      phone1: "",
      ie_rg: "",
    },
    boletoDesc: null,
  });
  const [products, setPorducts] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [openRefuse, setOpenRefuse] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [totalProduct, setTotalProduct] = useState(0);

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      const { data } = await orderService.get(id);
      setData(data.order);
      setPorducts(data.productsGrouped);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (data.products.length > 0) {
      const quantity = data.products.reduce(
        (previousValue, currentValue) => previousValue + currentValue.quantity,
        0
      );
      setTotalProduct(quantity);
    }
  }, [data.products, setTotalProduct]);

  const updateStatus = async (status) => {
    try {
      dispatch(startLoading());
      await orderService.update({ id, status });
      enqueueSnackbar("Mudança de status realizada com sucesso", {
        variant: "success",
      });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const refuseCancellation = async () => {
    try {
      dispatch(startLoading());
      await orderService.refuseCancellation(id);
      enqueueSnackbar("Solicitação de cancelamento recusado com sucesso", {
        variant: "success",
      });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const cancelOrder = async () => {
    try {
      dispatch(startLoading());
      await orderService.cancelOrder(id);
      enqueueSnackbar("Solicitação de cancelamento enviado com sucesso", {
        variant: "success",
      });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const uploadFiles = async (event) => {
    try {
      dispatch(startLoading());

      if (event.target.files.length > 0) {
        const formData = new FormData();
        Array.from(event.target.files).forEach((file) => {
          formData.append("file", file);
        });

        await orderService.uploadNfes(id, formData);
        getData();
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        "@media print": {
          padding: 0,
        },
      }}
    >
      <Fab
        color="primary"
        aria-label="download"
        sx={{
          position: "fixed",
          right: 0,
          bottom: 0,
          margin: 2,
          displayPrint: "none",
        }}
        onClick={() => window.print()}
      >
        <Download />
      </Fab>

      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "background.paper",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          display: "none",
          displayPrint: "initial",
        }}
      >
        <Toolbar sx={{ height: (theme) => theme.headerHeight }}>
          <img src={logo} alt="Logo da Vetline" height={35} />

          <Box flexGrow={1} />

          <Typography variant="body1" color="black" ml={1}>
            Pedido {data.reference ? data.reference : "---"}
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        marginBottom={4}
        sx={{ displayPrint: "none" }}
      >
        <Grid item>
          <Typography variant="h5" component="h1" lineHeight={1.55}>
            Pedido {data.reference ? data.reference : "---"}
          </Typography>
        </Grid>
        <Grid item>
          <Grid item container spacing={2}>
            {data.status === "Pagamento confirmado" ||
            (data.status === "Aguardando confirmação do pagamento" &&
              data.paymentMethod === "Boleto bancário") ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => updateStatus(1)}
                  sx={(theme) => ({
                    [theme.breakpoints.down("xs")]: {
                      padding: "4px 10px",
                      fontSize: "0.8125rem",
                    },
                  })}
                >
                  Mudar status para 'Pedido em separação'
                </Button>
              </Grid>
            ) : null}
            {data.status === "Pedido em separação" ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => updateStatus(2)}
                  sx={(theme) => ({
                    [theme.breakpoints.down("xs")]: {
                      padding: "4px 10px",
                      fontSize: "0.8125rem",
                    },
                  })}
                >
                  Mudar status para 'Pedido em rota de entrega'
                </Button>
              </Grid>
            ) : null}
            {data.status === "Pedido em rota de entrega" ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => updateStatus(3)}
                  sx={(theme) => ({
                    [theme.breakpoints.down("xs")]: {
                      padding: "4px 10px",
                      fontSize: "0.8125rem",
                    },
                  })}
                >
                  Mudar status para 'Pedido entregue'
                </Button>
              </Grid>
            ) : null}
            {data.status === "Aguardando cancelamento do pedido" ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setOpenRefuse(true)}
                  sx={(theme) => ({
                    [theme.breakpoints.down("xs")]: {
                      padding: "4px 10px",
                      fontSize: "0.8125rem",
                    },
                  })}
                >
                  Recusar Cancelamento
                </Button>
              </Grid>
            ) : null}

            {data.status !== "Pedido entregue" &&
            data.status !== "Esperando retorno da instituição financeira" &&
            data.status !== "Pedido cancelado" ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setOpenCancel(true)}
                  sx={(theme) => ({
                    [theme.breakpoints.down("xs")]: {
                      padding: "4px 10px",
                      fontSize: "0.8125rem",
                    },
                  })}
                >
                  Cancelar Pedido
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ displayPrint: "none" }}>
        <Grid item sm={12} md={8.5} style={{ width: "100%" }}>
          <Card sx={{ overflowX: "auto" }}>
            <CardContent>
              {products
                ? Object.keys(products).map((key, idx) => (
                    <>
                      <Typography variant="h6" mt={idx === 0 ? 0 : 2}>
                        {key}
                      </Typography>
                      <Table size="small">
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: 500,
                              width: "50%",
                              minWidth: 150,
                            }}
                          >
                            Produto
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 500,
                              width: "10%",
                              minWidth: 80,
                            }}
                            align="right"
                          >
                            Referência
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 500,
                              width: "10%",
                              minWidth: 80,
                            }}
                            align="right"
                          >
                            Qtd.
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 500,
                              width: "10%",
                              minWidth: 100,
                            }}
                            align="right"
                          >
                            Preço
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 500,
                              width: "10%",
                              minWidth: 100,
                            }}
                            align="right"
                          >
                            Desconto
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 500,
                              width: "10%",
                              minWidth: 100,
                            }}
                            align="right"
                          >
                            Preço Final
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 500,
                              width: "10%",
                              minWidth: 100,
                            }}
                            align="right"
                          >
                            Preço Total
                          </TableCell>
                        </TableRow>
                        <TableBody>
                          {products[key].map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  width="100%"
                                >
                                  <Avatar
                                    alt={row.product.name}
                                    src={`${process.env.REACT_APP_URL_API}/image/${row.product.photos[0].filename}`}
                                    sx={{ width: 56, height: 56 }}
                                  />
                                  <Typography variant="body2" pl={1.5}>
                                    {row.product.name}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell align="right">
                                {row.product.ref}
                              </TableCell>
                              <TableCell align="right">
                                {row.quantity}
                              </TableCell>
                              <TableCell align="right">
                                {moneyFormatter(row.amountWithoutDiscount)}
                              </TableCell>
                              <TableCell align="right">
                                {moneyFormatter(
                                  row.amountWithoutDiscount - row.amount
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {moneyFormatter(row.amount)}
                              </TableCell>
                              <TableCell align="right">
                                {moneyFormatter(row.amount * row.quantity)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  ))
                : null}
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={3.5} spacing={2}>
          <Grid item container spacing={2}>
            <Grid item style={{ width: "100%" }}>
              <Card>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Typography variant="h6">Status</Typography>
                  <Typography>{data.status}</Typography>
                  <Typography variant="body2">
                    Atualizado em{" "}
                    {moment(data.dateUpdated).format("DD/MM/yyyy - HH:mm:ss")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Card>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Typography variant="h6">Resumo do pedido</Typography>
                  <Box mt={1} display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>Pedido criado</Typography>
                    <Typography>
                      {moment(data.dateCreated).format("DD/MM/yyyy")}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>Hora do pedido</Typography>
                    <Typography>
                      {moment(data.dateCreated).format("HH:mm:ss")}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>Forma de pagamento</Typography>
                    <Typography>{data.paymentMethod}</Typography>
                  </Box>
                  {data.installment ? (
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontWeight={500}>Parcelamento</Typography>
                      <Typography>{data.installment}x</Typography>
                    </Box>
                  ) : null}
                  {data.boletoDesc ? (
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontWeight={500}>Descrição</Typography>
                      <Typography>{data.boletoDesc}</Typography>
                    </Box>
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Card>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>Total</Typography>
                    <Typography>{moneyFormatter(data.amount)}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Card>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Typography variant="h6">Comprador</Typography>
                  <Box mt={1} display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>Nome</Typography>
                    <Typography>
                      {data.user.isPJ
                        ? data.user.corporateName_firstName
                        : `${data.user.corporateName_firstName} ${data.user.fantasyName_lastName}`}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>CPF/CNPJ</Typography>
                    <Typography>
                      {data.user.isPJ
                        ? cnpjFormatter(data.user.cnpj_cpf)
                        : cpfFormatter(data.user.cnpj_cpf)}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>RG/IE</Typography>
                    <Typography>
                      {data.user.isPJ
                        ? data.user.ie_rg
                        : rgFormatter(data.user.ie_rg)}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>E-mail</Typography>
                    <Typography>{data.user.email}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>Telefone</Typography>
                    <Typography>{phoneFormatter(data.user.phone1)}</Typography>
                  </Box>
                  {data.user.phone2 ? (
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontWeight={500}>Telefone</Typography>
                      <Typography>
                        {phoneFormatter(data.user.phone2)}
                      </Typography>
                    </Box>
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Card>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Typography variant="h6">Endereço de entrega</Typography>
                  <Box mt={1} display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>CEP</Typography>
                    <Typography>{cepFormatter(data.zipCode)}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>Rua</Typography>
                    <Typography>
                      {data.street}, {data.number ? data.number : "SN"}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>Bairro</Typography>
                    <Typography>{data.district}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={500}>Cidade - UF</Typography>
                    <Typography>
                      {data.city} - {data.state}
                    </Typography>
                  </Box>
                  {data.complement ? (
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontWeight={500}>Complemento</Typography>
                      <Typography>{data.complement}</Typography>
                    </Box>
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Card>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Typography variant="h6" mb={1}>
                    Nota fiscal eletrônica
                  </Typography>
                  {data.nfes.length > 0 ? (
                    <Stack spacing={0.5}>
                      {data.nfes.map((file) => (
                        <Button
                          color="info"
                          sx={{
                            textTransform: "initial",
                            textAlign: "left",
                            width: "auto",
                            display: "block",
                            padding: 0,
                            "&:hover": {
                              background: "transparent !important",
                            },
                          }}
                          variant="text"
                          component={Link}
                          href={`${
                            process.env.REACT_APP_URL_API
                          }/download/nfe/${
                            file.filename
                          }/${sessionStorage.getItem("vl_token")}/0`}
                          target="_blank"
                          rel="noopener"
                        >
                          Nota fiscal eletrônica em {file.extname.toUpperCase()}
                        </Button>
                      ))}
                    </Stack>
                  ) : (
                    <Button
                      variant="outlined"
                      component="label"
                      sx={{ textTransform: "initial" }}
                      endIcon={<UploadFile />}
                      color="info"
                      onChange={uploadFiles}
                    >
                      Enviar NF-e (PDF/XML)
                      <input
                        hidden
                        accept="application/pdf, application/xml"
                        multiple
                        type="file"
                        name="file"
                      />
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={openRefuse}
        onClose={() => setOpenRefuse(false)}
        sx={{ displayPrint: "none" }}
      >
        <DialogContent>
          <DialogContentText color="textPrimary">
            Tem certeza que deseja prosseguir com a recusa do cancelamento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRefuse(false)} autoFocus>
            Não
          </Button>
          <Button
            onClick={() => {
              refuseCancellation();
              setOpenRefuse(false);
            }}
            color="error"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCancel}
        onClose={() => setOpenCancel(false)}
        sx={{ displayPrint: "none" }}
      >
        <DialogContent>
          <DialogContentText color="textPrimary">
            Tem certeza que deseja prosseguir com o cancelamento do pedido?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancel(false)} autoFocus>
            Não
          </Button>
          <Button
            onClick={() => {
              cancelOrder();
              setOpenCancel(false);
            }}
            color="error"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        spacing={2}
        sx={{ display: "none", displayPrint: "block" }}
      >
        <Grid item>
          <Card>
            <CardContent style={{ paddingBottom: 16 }}>
              <Typography fontWeight={500}>
                Cliente:{" "}
                <span style={{ fontWeight: 400 }}>
                  {data.user.isPJ
                    ? data.user.corporateName_firstName
                    : `${data.user.corporateName_firstName} ${data.user.fantasyName_lastName}`}
                </span>
              </Typography>
              <Grid container width="100%">
                <Grid item xs={6}>
                  <Typography fontWeight={500}>
                    CPF/CNPJ:{" "}
                    <span style={{ fontWeight: 400 }}>
                      {data.user.isPJ
                        ? cnpjFormatter(data.user.cnpj_cpf)
                        : cpfFormatter(data.user.cnpj_cpf)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={500}>
                    RG/IE:{" "}
                    <span style={{ fontWeight: 400 }}>
                      {data.user.isPJ
                        ? data.user.ie_rg
                        : rgFormatter(data.user.ie_rg)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={500}>
                    Rua:{" "}
                    <span style={{ fontWeight: 400 }}>
                      {data.street}, {data.number ? data.number : "SN"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={500}>
                    Bairro:{" "}
                    <span style={{ fontWeight: 400 }}>{data.district}</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={500}>
                    Cidade - UF:{" "}
                    <span style={{ fontWeight: 400 }}>
                      {data.city} - {data.state}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={500}>
                    CEP:{" "}
                    <span style={{ fontWeight: 400 }}>
                      {cepFormatter(data.zipCode)}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Typography fontWeight={500}>
                Complemento:{" "}
                <span style={{ fontWeight: 400 }}>
                  {data.complement ? data.complement : ""}
                </span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <TableContainer component={Paper}>
            {products
              ? Object.keys(products).map((key, idx) => (
                  <>
                    <Typography variant="h6" mt={idx === 0 ? 0 : 2} mx={2}>
                      {key}
                    </Typography>
                    <Table size="small">
                      <TableRow>
                        <TableCell sx={{ fontWeight: 500 }}>Produto</TableCell>
                        <TableCell sx={{ fontWeight: 500 }} align="right">
                          Qtd.
                        </TableCell>
                        <TableCell sx={{ fontWeight: 500 }} align="right">
                          Preço
                        </TableCell>
                        <TableCell sx={{ fontWeight: 500 }} align="right">
                          Desconto
                        </TableCell>
                        <TableCell sx={{ fontWeight: 500 }} align="right">
                          Preço Final
                        </TableCell>
                        <TableCell sx={{ fontWeight: 500 }} align="right">
                          Preço Total
                        </TableCell>
                      </TableRow>
                      <TableBody>
                        {products[key].map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.product.name}</TableCell>
                            <TableCell align="right">{row.quantity}</TableCell>
                            <TableCell align="right">
                              {moneyFormatter(row.amountWithoutDiscount)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyFormatter(
                                row.amountWithoutDiscount - row.amount
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {moneyFormatter(row.amount)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyFormatter(row.amount * row.quantity)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ))
              : null}
          </TableContainer>
        </Grid>

        <Grid item>
          <Card>
            <CardContent style={{ paddingBottom: 16 }}>
              <Grid container width="100%">
                <Grid item xs={6}>
                  <Typography fontWeight={500}>
                    Totais de produtos:{" "}
                    <span style={{ fontWeight: 400 }}>{totalProduct}</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={500}>
                    Valor total:{" "}
                    <span style={{ fontWeight: 400 }}>
                      {moneyFormatter(data.amount)}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OrderDetailsView;
