import api from "../common/api";

const getAll = () => api.get("/product-historic");

const get = (id) => api.get(`/product-historic/${id}`);

const create = (data) => api.post("/product-historic", data);

const productHistoricService = { getAll, create, get };

export default productHistoricService;
