import api from "../common/api";

const getAll = () => api.get("communications/news");

const get = (id) => api.get(`communications/news/${id}`);

const create = () => api.post("communications/news");

const uploadPhoto = (id, data) =>
  api.post(`communications/news/${id}/photo`, data);

const update = (id, data) => api.put(`communications/news/${id}`, data);

const remove = (id) => api.delete(`communications/news/${id}`);

const newsService = {
  getAll,
  get,
  create,
  update,
  remove,
  uploadPhoto,
};

export default newsService;
