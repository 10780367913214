import {
  CurrencyExchangeOutlined,
  Inventory2Outlined,
  PaymentOutlined,
  ShoppingCart,
  TrendingUpOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectDrawer } from "../../store/drawerSlice";
import { getErrorMessage, moneyFormatter2 } from "../../utils";
import { useSnackbar } from "notistack";
import orderService from "../../services/order";

const DashBoardView = () => {
  const drawer = useSelector(selectDrawer);
  const { enqueueSnackbar } = useSnackbar();
  const [dashboardInfo, setDashboardInfo] = useState({
    totalOrderMonth: 0,
    totalOrderConfirm: "0",
    totalOrderCancel: "0",
  });
  const [loadingData, setLoadingData] = useState(false);

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await orderService.dashboardInfo();
      setDashboardInfo(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Box
        display="block"
        position="absolute"
        zIndex={-1}
        bgcolor="#a0cd51"
        height="35vh"
        sx={(theme) => ({
          width: "100%",
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawer ? theme.sidebarWidth : 0}px)`,
          },
        })}
      />
      <Box
        mt="14vh"
        px={3}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          color="white"
          variant="h4"
          component="h1"
          textAlign="center"
        >
          Que tal esses atalhos?
        </Typography>

        <Box
          my={1.5}
          display="flex"
          gap={2}
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          maxWidth={670}
        >
          <Card elevation={5} sx={{ width: 210, height: 170 }}>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CurrencyExchangeOutlined color="primary" fontSize="large" />
                <Typography fontWeight="bold" textAlign="center">
                  Financeiro
                </Typography>
                <Typography variant="caption" mb={1.5} textAlign="center">
                  Analise o financeiro
                </Typography>
                <Button variant="outlined" component={Link} to="finance">
                  Acessar
                </Button>
              </Box>
            </CardContent>
          </Card>
          <Card elevation={5} sx={{ width: 210, height: 170 }}>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <TrendingUpOutlined color="primary" fontSize="large" />
                <Typography fontWeight="bold" textAlign="center">
                  Resultado de Vendas
                </Typography>
                <Typography variant="caption" mb={1.5} textAlign="center">
                  Analise o resultado das vendas
                </Typography>
                <Button variant="outlined" component={Link} to="sales">
                  Acessar
                </Button>
              </Box>
            </CardContent>
          </Card>
          <Card elevation={5} sx={{ width: 210, height: 170 }}>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Inventory2Outlined color="primary" fontSize="large" />
                <Typography fontWeight="bold" textAlign="center">
                  Estoque
                </Typography>
                <Typography variant="caption" mb={1.5} textAlign="center">
                  Ajuste o estoque
                </Typography>
                <Button variant="outlined" component={Link} to="stock">
                  Acessar
                </Button>
              </Box>
            </CardContent>
          </Card>
          <Card elevation={5} sx={{ width: 210, height: 170 }}>
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography fontWeight="bold" textAlign="center">
                  Total de pedidos no mês
                </Typography>
                <Divider flexItem />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <ShoppingCart color="primary" fontSize="medium" />
                      <Typography color="red" fontSize="large">
                        {dashboardInfo.totalOrderMonth}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card elevation={5} sx={{ width: 210, height: 170 }}>
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography fontWeight="bold" textAlign="center">
                  Vendas comfirmadas no mês
                </Typography>
                <Divider flexItem />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <PaymentOutlined color="primary" fontSize="medium" />
                      <Typography fontSize="small">
                        R${" "}
                        <Typography
                          color="red"
                          display="inline"
                          fontSize="large"
                        >
                          {moneyFormatter2(dashboardInfo.totalOrderConfirm)}
                        </Typography>
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card elevation={5} sx={{ width: 210, height: 170 }}>
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography fontWeight="bold" textAlign="center">
                  Vendas canceladas no mês
                </Typography>
                <Divider flexItem />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <PaymentOutlined color="primary" fontSize="medium" />
                      <Typography fontSize="small">
                        R${" "}
                        <Typography
                          color="red"
                          display="inline"
                          fontSize="large"
                        >
                          {moneyFormatter2(dashboardInfo.totalOrderCancel)}
                        </Typography>
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default DashBoardView;
