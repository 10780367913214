import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import yup from "../../common/validator";
import DataGrid from "../../components/dataGrid";
import TabPanel from "../../components/tabPanel";
import { useDispatch } from "react-redux";
import permissionService from "../../services/permission";
import settingsService from "../../services/settings";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import { rolePermissionColumns } from "./columns";
import NumberFormat from "react-number-format";

const redeValidation = {
  pv: yup.string().trim().nullable(),
  token: yup.string().trim().nullable(),
  isProduction: yup.boolean(),
};

const nodemailerValidation = {
  host: yup.string().trim().nullable(),
  port: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  email: yup.string().trim().email().nullable(),
  password: yup.string().trim().nullable(),
  secure: yup.boolean(),
};

const othersValidation = {
  installmentsWithoutFees: yup
    .number()
    .integer()
    .transform((value) => (isNaN(value) ? 0 : value)),
  installmentsWithFees: yup
    .number()
    .integer()
    .transform((value) => (isNaN(value) ? 0 : value)),
  fees: yup.number().nullable(),
  cancellationLimitCredit: yup
    .number()
    .positive()
    .integer()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  cancellationLimitDebit: yup
    .number()
    .positive()
    .integer()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  cancellationLimitBoleto: yup
    .number()
    .positive()
    .integer()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  minimumAmount: yup
    .string()
    .required()
    .transform((currentValue) => {
      return currentValue.replace(/[^0-9,.]/g, "").replace(",", ".");
    }),
};

const SettingsView = () => {
  const [valueTab, setValueTab] = useState(0);
  const [roleData, setRoleData] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formRede = useForm({
    resolver: yupResolver(yup.object().shape(redeValidation)),
  });
  const formNodemailer = useForm({
    resolver: yupResolver(yup.object().shape(nodemailerValidation)),
  });
  const formOthers = useForm({
    resolver: yupResolver(yup.object().shape(othersValidation)),
  });

  const tabToggle = (event, newValue) => {
    setValueTab(newValue);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      const [role, rede, nodemailer] = await Promise.all([
        permissionService.getAllRole(),
        settingsService.getRede(),
        settingsService.getNodemailer(),
      ]);
      setRoleData(role.data);
      formRede.reset({
        pv: rede.data.pv,
        token: rede.data.token,
        isProduction: rede.data.isProduction,
      });
      formNodemailer.reset(nodemailer.data);
      formOthers.reset({
        installmentsWithoutFees: rede.data.installmentsWithoutFees,
        installmentsWithFees: rede.data.installmentsWithFees,
        fees: rede.data.fees,
        cancellationLimitCredit: rede.data.cancellationLimitCredit,
        cancellationLimitDebit: rede.data.cancellationLimitDebit,
        cancellationLimitBoleto: rede.data.cancellationLimitBoleto,
        minimumAmount: rede.data.minimumAmount,
      });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, formNodemailer, formOthers, formRede]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickDeleteRole = async (id) => {
    try {
      dispatch(startLoading());
      await permissionService.removeRole(id);
      enqueueSnackbar("Remoção realizada com sucesso", {
        variant: "success",
      });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onSubmitRede = async (data) => {
    try {
      dispatch(startLoading());
      await settingsService.updateRede(data);
      enqueueSnackbar("Salvo com sucesso", { variant: "success" });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onSubmitNodemailer = async (data) => {
    try {
      dispatch(startLoading());
      await settingsService.updateNodemailer(data);
      enqueueSnackbar("Salvo com sucesso", { variant: "success" });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <Box
        position="static"
        bgcolor="white"
        sx={{
          borderRadius: "4px 4px 0 0",
          boxShadow: (theme) => theme.shadows[1],
        }}
      >
        <Tabs value={valueTab} onChange={tabToggle} variant="scrollable">
          <Tab label="Grupo de Permissões" {...a11yProps(0)} />
          <Tab label="Rede" {...a11yProps(1)} />
          <Tab label="Nodemailer" {...a11yProps(2)} />
          <Tab label="Compras" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <TabPanel value={valueTab} index={0}>
        <CardContent>
          <DataGrid
            rows={roleData}
            columns={rolePermissionColumns}
            onClickDelete={onClickDeleteRole}
            editTo={(id) => `form-rolepermission/${id}`}
            createTo="form-rolepermission"
          />
        </CardContent>
      </TabPanel>

      <TabPanel value={valueTab} index={1}>
        <form onSubmit={formRede.handleSubmit(onSubmitRede)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={formRede.control}
                  name="pv"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="PV"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={formRede.control}
                  name="token"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Token"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={formRede.control}
                  name="isProduction"
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      label="Modo de Produção"
                      control={
                        <Switch
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button type="submit">Salvar</Button>
          </CardActions>
        </form>
      </TabPanel>

      <TabPanel value={valueTab} index={2}>
        <form onSubmit={formNodemailer.handleSubmit(onSubmitNodemailer)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={formNodemailer.control}
                  name="host"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Host"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={formNodemailer.control}
                  name="port"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Porta"
                      fullWidth
                      type="number"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={formNodemailer.control}
                  name="email"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="E-mail"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={formNodemailer.control}
                  name="password"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Senha"
                      type="password"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={formNodemailer.control}
                  name="secure"
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      label="Secure"
                      control={
                        <Switch
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button type="submit">Salvar</Button>
          </CardActions>
        </form>
      </TabPanel>

      <TabPanel value={valueTab} index={3}>
        <form onSubmit={formOthers.handleSubmit(onSubmitRede)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={formOthers.control}
                  name="minimumAmount"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                      customInput={TextField}
                      label="Valor mínimo de compra"
                      fullWidth
                      prefix="R$ "
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={formOthers.control}
                  name="fees"
                  defaultValue={null}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <NumberFormat
                      customInput={TextField}
                      label="Juros de parcelamento (a.m.)"
                      fullWidth
                      suffix="%"
                      decimalScale={2}
                      decimalSeparator=","
                      error={!!error}
                      helperText={error?.message}
                      onValueChange={(value) => onChange(value.floatValue)}
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={formOthers.control}
                  name="installmentsWithoutFees"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Quantidade de parcelas sem juros (Cartão de Crédito)"
                      fullWidth
                      type="number"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={formOthers.control}
                  name="installmentsWithFees"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Quantidade de parcelas com juros (Cartão de Crédito)"
                      fullWidth
                      type="number"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={formOthers.control}
                  name="cancellationLimitCredit"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Dias disponíveis para cancelamento (Cartão de Crédito)"
                      fullWidth
                      type="number"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={formOthers.control}
                  name="cancellationLimitDebit"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Dias disponíveis para cancelamento (Cartão de Débito)"
                      fullWidth
                      type="number"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={formOthers.control}
                  name="cancellationLimitBoleto"
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Dias disponíveis para cancelamento (Boleto Bancário)"
                      fullWidth
                      type="number"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button type="submit">Salvar</Button>
          </CardActions>
        </form>
      </TabPanel>
    </>
  );
};
export default SettingsView;
