import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DataGrid from "../../components/dataGrid";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import saleBankSlipService from "../../services/saleBankSlip";

const SaleBankSlipView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const columns = [
    { field: "name", headerName: "Nome", flex: 1, minWidth: 150 },
    { field: "type", headerName: "Tipo de Pagamento", flex: 1, minWidth: 150 },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await saleBankSlipService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickDelete = async (id) => {
    try {
      dispatch(startLoading());
      await saleBankSlipService.remove(id);
      enqueueSnackbar("Remoção realizada com sucesso", {
        variant: "success",
      });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <DataGrid
      rows={data}
      columns={columns}
      loading={loadingData}
      editTo={(id) => `form/${id}`}
      onClickDelete={onClickDelete}
    />
  );
};

export default SaleBankSlipView;
