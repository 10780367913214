import api from "../common/api";

const signIn = (data) => api.post("/auth/login", data);

const validateToken = (token) => api.get(`/auth/check/${token}`);

const recoverPassword = (email) => api.get(`/auth/recover/${email}`);

const verifyToken = (token) => api.get(`/auth/check/${token}/recover`);

const updatePassword = (data) =>
  api.patch("/auth/recover/update-password", data);

const authService = {
  signIn,
  validateToken,
  recoverPassword,
  verifyToken,
  updatePassword,
};

export default authService;
