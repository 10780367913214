import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import TabPanel from "../../components/tabPanel";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import yup from "../../common/validator";
import { Controller, useForm } from "react-hook-form";
import productService from "../../services/product";
import { Link, useNavigate, useParams } from "react-router-dom";
import categoryService from "../../services/category";
import brandService from "../../services/brand";
import UploadImage from "../../components/uploadImage";
import NumberFormat from "react-number-format";
import DayHoursWeek from "../../components/dayHoursWeek";

const validation = {
  photos: yup.array().min(1),
  name: yup.string().trim().required(),
  ref: yup.string().trim().required(),
  amount: yup
    .string()
    .required()
    .transform((currentValue) => {
      return currentValue.replace(/[^0-9,.]/g, "").replace(",", ".");
    }),
  brand: yup.object({ id: yup.number(), name: yup.string() }),
  categories: yup.array().min(1),
  stock: yup.number().min(0).integer().required(),
  description: yup.string().trim().nullable(),
  specification: yup.string().trim().nullable(),
  amountPromotion: yup.string().transform((currentValue) => {
    return currentValue
      ? currentValue.replace(/[^0-9,.]/g, "").replace(",", ".")
      : "";
  }),
  active: yup.boolean(),
  recommended: yup.boolean(),
  promotionAvailability: yup.array(),
  qtdCaixa: yup
    .number()
    .min(0)
    .nullable()
    .transform((value) => (isNaN(value) ? null : value === 0 ? null : value)),
};

const ProductFormView = () => {
  const [valueTab, setValueTab] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const { control, handleSubmit, reset, setValue, watch } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const photos = watch("photos");
  const promotionAvailability = watch("promotionAvailability");
  const navigate = useNavigate();

  const tabToggle = (event, newValue) => {
    setValueTab(newValue);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const [product, category, brand] = await Promise.all([
          productService.get(id),
          categoryService.getAll(),
          brandService.getAll(),
        ]);
        reset(product.data);
        setCategory(category.data);
        setBrand(brand.data);
      } else {
        const [category, brand] = await Promise.all([
          categoryService.getAll(),
          brandService.getAll(),
        ]);
        setCategory(category.data);
        setBrand(brand.data);
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..");
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (key === "photos") {
        object[key].forEach((file) => {
          if (file.id) {
            formData.append("file", JSON.stringify(file));
          } else {
            if (file.fileUrl) {
              URL.revokeObjectURL(file.fileUrl);
            }
            formData.append("file", file);
          }
        });
      } else if (typeof object[key] === "object" && object[key] !== null) {
        formData.append(key, JSON.stringify(object[key]));
      } else {
        formData.append(key, object[key]);
      }
    });

    return formData;
  };

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      const formData = getFormData(data);
      id
        ? await productService.update(formData, id)
        : await productService.create(formData);
      enqueueSnackbar(`${id ? "Edição" : "Cadastro"} realizado com sucesso`, {
        variant: "success",
      });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onError = (error) => {
    if (
      error.photos ||
      error.name ||
      error.ref ||
      error.amount ||
      error.brand ||
      error.categories
    ) {
      setValueTab(0);
    }
  };

  return (
    <>
      <Box
        position="static"
        bgcolor="white"
        sx={{
          borderRadius: "4px 4px 0 0",
          boxShadow: (theme) => theme.shadows[1],
        }}
      >
        <Tabs value={valueTab} onChange={tabToggle} variant="scrollable">
          <Tab label="Dados" {...a11yProps(0)} />
          <Tab label="Promoção" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <TabPanel value={valueTab} index={0}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UploadImage
                  control={control}
                  name="photos"
                  file={photos}
                  setValue={setValue}
                  isMultiple
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="name"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Nome"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="ref"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Referência"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="qtdCaixa"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Unidades por Caixa"
                      fullWidth
                      type="number"
                      error={!!error}
                      helperText={
                        !!error
                          ? error?.message
                          : "Ao definir o campo 'Unidades por Caixa', você garantirá que os clientes só possam comprar em quantidades que sejam múltiplas desse valor."
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="amount"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                      customInput={TextField}
                      label="Valor"
                      fullWidth
                      prefix="R$ "
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="brand"
                  defaultValue={null}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      {...rest}
                      options={brand}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      fullWidth
                      onChange={(event, value) => onChange(value)}
                      renderInput={(params) => (
                        <TextField
                          label="Fabricante"
                          error={!!error}
                          helperText={!!error && "O campo é obrigatório."}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="categories"
                  defaultValue={[]}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      {...rest}
                      options={category}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      fullWidth
                      multiple
                      onChange={(event, value) => onChange(value)}
                      renderInput={(params) => (
                        <TextField
                          label="Categoria"
                          error={!!error}
                          helperText={!!error && "O campo é obrigatório."}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="stock"
                  defaultValue={0}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Estoque"
                      fullWidth
                      type="number"
                      error={!!error}
                      helperText={
                        !!error
                          ? error?.message
                          : id
                          ? "Alteração de estoque nesta página não será adicionado registro de entrada e saída, para isso utilize o menu Estoque."
                          : ""
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="description"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Descrição"
                      fullWidth
                      multiline
                      minRows={3}
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="specification"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Ficha Técnica"
                      fullWidth
                      multiline
                      minRows={3}
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="active"
                  control={control}
                  defaultValue
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Ativo"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="recommended"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Produto Recomendado"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button component={Link} to=".." color="error">
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </CardActions>
        </TabPanel>

        <TabPanel value={valueTab} index={1}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="amountPromotion"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                      customInput={TextField}
                      label="Valor Promocional"
                      fullWidth
                      prefix="R$ "
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <DayHoursWeek
                  name="promotionAvailability"
                  availability={promotionAvailability}
                  setValue={setValue}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button component={Link} to=".." color="error">
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </CardActions>
        </TabPanel>
      </form>
    </>
  );
};
export default ProductFormView;
