import api from "../common/api";

const getAll = () => api.get("/promotion");

const create = (data) => api.post("/promotion", data);

const remove = (id) => api.delete(`/promotion/${id}`);

const promotionService = { getAll, create, remove };

export default promotionService;
