import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../common/validator";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import productService from "../../services/product";
import couponService from "../../services/coupon";
import categoryService from "../../services/category";
import brandsService from "../../services/brand";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import NumberFormat from "react-number-format";
import "moment/locale/pt-br";

const validation = {
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  name: yup.string().trim().required(),
  code: yup.string().trim().required(),
  percentage: yup
    .string()
    .required()
    .transform((currentValue) => {
      if (currentValue) {
        const newValue = currentValue.replace(/[^0-9,]/g, "").replace(",", ".");
        return newValue;
      }
      return "";
    }),
  products: yup.array().nullable(),
  categories: yup.array().nullable(),
  brands: yup.array().nullable(),
};

const CouponFormView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [brands, setBrands] = useState([]);

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const [products, coupon, category, brands] = await Promise.all([
          productService.getAll(),
          couponService.get(id),
          categoryService.getAll(),
          brandsService.getAll(),
        ]);
        setProducts(products.data);
        reset(coupon.data);
        setCategory(category.data);
        setBrands(brands.data);
      } else {
        const [products, category, brands] = await Promise.all([
          productService.getAll(),
          categoryService.getAll(),
          brandsService.getAll(),
        ]);
        setProducts(products.data);
        setCategory(category.data);
        setBrands(brands.data);
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..", { replace: true });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      id ? await couponService.update(data) : await couponService.create(data);
      enqueueSnackbar(`${id ? "Edição" : "Cadastro"} realizado com sucesso`, {
        variant: "success",
      });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="startDate"
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <DateTimePicker
                      renderInput={(props) => (
                        <TextField fullWidth {...props} />
                      )}
                      label="Data de Início"
                      {...field}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="endDate"
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <DateTimePicker
                      renderInput={(props) => (
                        <TextField
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                          {...props}
                        />
                      )}
                      label="Data de Finalização"
                      {...field}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Nome"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name="code"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Código"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name="percentage"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <NumberFormat
                    customInput={TextField}
                    label="Porcentagem"
                    fullWidth
                    suffix="%"
                    decimalScale={2}
                    decimalSeparator=","
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="products"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={products}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Produtos Vinculados"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="categories"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={category}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Categorias Vinculadas"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="brands"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={brands}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Fornecedores Vinculados"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button component={Link} to=".." color="error">
            Cancelar
          </Button>
          <Button type="submit">Salvar</Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default CouponFormView;
