import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../../common/validator";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../../store/loadingSlice";
import { getErrorMessage } from "../../../utils";
import TextEditor from "../../../components/textEditor";
import apiService from "../../../services/universidadeNoticias";

const validation = {
  title: yup.string().required(),
  publish: yup.boolean(),
  text: yup.string().nullable(),
};

const UniversidadeNewsFormView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [item, setItem] = useState();

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const { data } = await apiService.get(id);
        setItem(data);
        reset({ title: data.title, publish: data.publish, text: data.text });
      } else {
        const { data } = await apiService.create();
        navigate(`${data.id}`, { replace: true });
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..", { replace: true });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      await apiService.update(id, data);
      enqueueSnackbar(`Edição realizado com sucesso`, {
        variant: "success",
      });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onSubmitCapa = async (file) => {
    try {
      dispatch(startLoading());

      const formData = new FormData();
      formData.append("file", file);
      await apiService.addImagem(id, formData);

      const { data } = await apiService.get(id);
      setItem(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Typography
                sx={{ fontSize: "1rem", fontWeight: "500", margin: 2 }}
              >
                Capa da notícia
              </Typography>
              <Divider sx={{ marginBottom: 1 }} />
              <CardContent>
                {item?.photoNameFile ? (
                  <img
                    alt="capa"
                    src={`${process.env.REACT_APP_URL_API}/image/${item.photoNameFile}`}
                    width="100%"
                  />
                ) : null}
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button component="label">
                  Enviar foto
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    name="file"
                    onChange={async (event) => {
                      if (event.target.files[0]) {
                        await onSubmitCapa(event.target.files[0]);
                      }
                    }}
                  />
                </Button>
              </CardActions>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Typography
                sx={{ fontSize: "1rem", fontWeight: "500", margin: 2 }}
              >
                Editar detalhes da notícia
              </Typography>
              <Divider sx={{ marginBottom: 1 }} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="title"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            label="Título"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="publish"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={field.value}
                                {...field}
                              />
                            }
                            label="Publicar"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {item && (
                        <Controller
                          name="text"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextEditor
                              onChange={field.onChange}
                              value={field.value}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button component={Link} to=".." color="error">
                    Cancelar
                  </Button>
                  <Button type="submit">Salvar</Button>
                </CardActions>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UniversidadeNewsFormView;
