import {
  FormGroup,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { useState } from "react";
import NumberFormat from "react-number-format";

const DayHoursWeek = ({ name, availability, setValue, disabled = false }) => {
  const [errors, setErrors] = useState([
    { start: false, end: false },
    { start: false, end: false },
    { start: false, end: false },
    { start: false, end: false },
    { start: false, end: false },
    { start: false, end: false },
    { start: false, end: false },
  ]);

  const onChangeCheckBox = (dayId) => {
    if (availability) {
      if (availability.findIndex((i) => i.day === dayId) > -1) {
        const newArray = availability.filter((i) => i.day !== dayId);
        setValue(name, newArray);
      } else {
        setValue(name, [...availability, { day: dayId }]);
      }
    } else {
      setValue(name, [{ day: dayId }]);
    }
  };

  const onChangeInput = (originalValue, dayId, index, start) => {
    if (originalValue.trim().length === 5) {
      const value = originalValue.split(":");
      const hour = parseInt(value[0], 10);
      const minutes = parseInt(value[1], 10);

      if (hour >= 0 && hour <= 23 && minutes >= 0 && minutes <= 59) {
        const newErrors = [...errors];
        if (start) {
          newErrors[index].start = false;
          setValue(
            name,
            availability?.map((i) => {
              if (i.day === dayId) {
                const newItem = { ...i };
                newItem.startTime = originalValue;
                return newItem;
              }
              return i;
            })
          );
        } else {
          newErrors[index].end = false;
          setValue(
            name,
            availability?.map((i) => {
              if (i.day === dayId) {
                const newItem = { ...i };
                newItem.endTime = originalValue;
                return newItem;
              }
              return i;
            })
          );
        }
        setErrors(newErrors);
      } else {
        const newErrors = [...errors];
        if (start) {
          newErrors[index].start = true;
          setValue(
            name,
            availability?.map((i) => {
              if (i.day === dayId) {
                const newItem = { ...i };
                newItem.startTime = null;
                return newItem;
              }
              return i;
            })
          );
        } else {
          newErrors[index].end = true;
          setValue(
            name,
            availability?.map((i) => {
              if (i.day === dayId) {
                const newItem = { ...i };
                newItem.endTime = null;
                return newItem;
              }
              return i;
            })
          );
        }
        setErrors(newErrors);
      }
    }
  };

  return (
    <FormGroup>
      <Grid container direction="column">
        <Grid item container alignItems="center" columnSpacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            <FormControlLabel
              label="Domingo"
              disabled={disabled}
              control={
                <Checkbox
                  checked={
                    availability
                      ? availability.findIndex((i) => i.day === 0) > -1
                      : false
                  }
                  onChange={() => onChangeCheckBox(0)}
                />
              }
            />
          </Grid>
          {availability && availability.findIndex((i) => i.day === 0) > -1 && (
            <>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Início"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 0)?.startTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 0, 0, true)
                  }
                  error={errors[0].start}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Fim"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 0)?.endTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 0, 0, false)
                  }
                  error={errors[0].end}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            <FormControlLabel
              label="Segunda"
              disabled={disabled}
              control={
                <Checkbox
                  checked={
                    availability
                      ? availability.findIndex((i) => i.day === 1) > -1
                      : false
                  }
                  onChange={() => onChangeCheckBox(1)}
                />
              }
            />
          </Grid>
          {availability && availability.findIndex((i) => i.day === 1) > -1 && (
            <>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Início"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 1)?.startTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 1, 1, true)
                  }
                  error={errors[1].start}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Fim"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 1)?.endTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 1, 1, false)
                  }
                  error={errors[1].end}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            <FormControlLabel
              label="Terça"
              disabled={disabled}
              control={
                <Checkbox
                  checked={
                    availability
                      ? availability.findIndex((i) => i.day === 2) > -1
                      : false
                  }
                  onChange={() => onChangeCheckBox(2)}
                />
              }
            />
          </Grid>
          {availability && availability.findIndex((i) => i.day === 2) > -1 && (
            <>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Início"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 2)?.startTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 2, 2, true)
                  }
                  error={errors[2].start}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Fim"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 2)?.endTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 2, 2, false)
                  }
                  error={errors[2].end}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            <FormControlLabel
              label="Quarta"
              disabled={disabled}
              control={
                <Checkbox
                  checked={
                    availability
                      ? availability.findIndex((i) => i.day === 3) > -1
                      : false
                  }
                  onChange={() => onChangeCheckBox(3)}
                />
              }
            />
          </Grid>
          {availability && availability.findIndex((i) => i.day === 3) > -1 && (
            <>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Início"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 3)?.startTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 3, 3, true)
                  }
                  error={errors[3].start}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Fim"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 3)?.endTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 3, 3, false)
                  }
                  error={errors[3].end}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            <FormControlLabel
              label="Quinta"
              disabled={disabled}
              control={
                <Checkbox
                  checked={
                    availability
                      ? availability.findIndex((i) => i.day === 4) > -1
                      : false
                  }
                  onChange={() => onChangeCheckBox(4)}
                />
              }
            />
          </Grid>
          {availability && availability.findIndex((i) => i.day === 4) > -1 && (
            <>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Início"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 4)?.startTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 4, 4, true)
                  }
                  error={errors[4].start}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Fim"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 4)?.endTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 4, 4, false)
                  }
                  error={errors[4].end}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            <FormControlLabel
              label="Sexta"
              disabled={disabled}
              control={
                <Checkbox
                  checked={
                    availability
                      ? availability.findIndex((i) => i.day === 5) > -1
                      : false
                  }
                  onChange={() => onChangeCheckBox(5)}
                />
              }
            />
          </Grid>
          {availability && availability.findIndex((i) => i.day === 5) > -1 && (
            <>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Início"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 5)?.startTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 5, 5, true)
                  }
                  error={errors[5].start}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Fim"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 5)?.endTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 5, 5, false)
                  }
                  error={errors[5].end}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            <FormControlLabel
              label="Sábado"
              disabled={disabled}
              control={
                <Checkbox
                  checked={
                    availability
                      ? availability.findIndex((i) => i.day === 6) > -1
                      : false
                  }
                  onChange={() => onChangeCheckBox(6)}
                />
              }
            />
          </Grid>
          {availability && availability.findIndex((i) => i.day === 6) > -1 && (
            <>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Início"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 6)?.startTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 6, 6, true)
                  }
                  error={errors[6].start}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                <NumberFormat
                  format="##:##"
                  customInput={TextField}
                  variant="outlined"
                  label="Fim"
                  placeholder="HH:MM"
                  margin="dense"
                  size="small"
                  fullWidth
                  value={availability.find((i) => i.day === 6)?.endTime}
                  onChange={(event) =>
                    onChangeInput(event.target.value, 6, 6, false)
                  }
                  error={errors[6].end}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export default DayHoursWeek;
