import api from "../common/api";

const getAll = () => api.get("/company");

const get = (id) => api.get(`/company/${id}`);

const create = (data) => api.post("/company", data);

const update = (data) => api.put(`/company/${data.id}`, data);

const remove = (id) => api.delete(`/company/${id}`);

const companyService = { getAll, get, create, update, remove };

export default companyService;
