import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { getErrorMessage } from "../../utils";
import productHistoricService from "../../services/productHistoric";
import { useParams } from "react-router-dom";
import moment from "moment";

const StockHistoricView = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const columns = [
    {
      field: "date",
      headerName: "Data e Hora",
      minWidth: 150,
      flex: 1,
      type: "dateTime",
      valueFormatter: ({ value }) =>
        moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "desc",
      headerName: "Descrição",
      flex: 4,
      minWidth: 150,
    },
    {
      field: "type",
      headerName: "Tipo",
      minWidth: 150,
      flex: 1,
      type: "singleSelect",
      valueOptions: ["Entrada", "Saida"],
    },
    {
      field: "quantity",
      headerName: "Quantidade",
      flex: 1,
      minWidth: 150,
      type: "number",
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await productHistoricService.get(id);
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return <DataGrid rows={data} columns={columns} loading={loadingData} />;
};

export default StockHistoricView;
