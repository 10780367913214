import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../common/validator";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import restricaoService from "../../services/restricao";
import productService from "../../services/product";
import { useState } from "react";
import estados from "../../utils/estados";
import cidades from "../../utils/cidades";
import { VariableSizeList } from "react-window";
import PropTypes from "prop-types";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" noWrap style={inlineStyle}>
      {dataSet}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const validation = {
  desc: yup.string().trim().required(),
  products: yup.array(),
  uf: yup.array(),
  cidade: yup.array(),
  cep: yup.array(),
};

const RestricaoFormView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id, idBrand } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const [restricao, products] = await Promise.all([
          restricaoService.get(id),
          productService.getByBrand(idBrand),
        ]);
        reset({
          ...restricao.data,
          cep: restricao.data.cep?.split(","),
          cidade: restricao.data.cidade?.split(","),
          uf: restricao.data.uf?.split(","),
        });
        setProducts(products.data);
      } else {
        const { data } = await productService.getByBrand(idBrand);
        setProducts(data);
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..", { replace: true });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, idBrand, navigate, reset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());

      const body = {
        desc: data.desc,
        brand: idBrand,
        products: data.products.map((v) => v.id),
        uf: data.uf.length ? data.uf.join(",") : null,
        cidade: data.cidade.length ? data.cidade.join(",") : null,
        cep: data.cep.length ? data.cep.join(",") : null,
      };

      if (id) {
        await restricaoService.update(id, body);
      } else {
        await restricaoService.create(body);
      }

      enqueueSnackbar(`${id ? "Edição" : "Cadastro"} realizado com sucesso`, {
        variant: "success",
      });
      navigate("..", { replace: true });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="desc"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Descrição"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="products"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={products}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Produtos"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="uf"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={estados.map((v) => v.id)}
                    getOptionLabel={(option) =>
                      estados.find((v) => v.id === option).label
                    }
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Estados"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="cidade"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={cidades}
                    fullWidth
                    multiple
                    ListboxComponent={ListboxComponent}
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Cidades"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="cep"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={[]}
                    freeSolo
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Cep"
                        error={!!error}
                        helperText={
                          !!error
                            ? "O campo é obrigatório."
                            : "Informe o CEP somente com números."
                        }
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button component={Link} to=".." color="error">
            Cancelar
          </Button>
          <Button type="submit">Salvar</Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default RestricaoFormView;
