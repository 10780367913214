import api from "../common/api";

const getAll = () => api.get("universidade/link");

const get = (id) => api.get(`universidade/link/${id}`);

const create = (data) => api.post("universidade/link", data);

const update = (id, data) => api.put(`universidade/link/${id}`, data);

const remove = (id) => api.delete(`universidade/link/${id}`);

const universidadeLinkService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default universidadeLinkService;
