import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { getErrorMessage } from "../../utils";
import productHistoricService from "../../services/productHistoric";
import moment from "moment";
import { Avatar, Box, Typography } from "@mui/material";

const ProductHistoricView = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const columns = [
    {
      field: "date",
      headerName: "Data e Hora",
      minWidth: 160,
      flex: 1,
      type: "dateTime",
      valueFormatter: ({ value }) =>
        moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "name",
      headerName: "Produto",
      flex: 4,
      minWidth: 150,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" width="100%">
          <Avatar
            alt={params.row.product.name}
            src={`${process.env.REACT_APP_URL_API}/image/${params.row.product.photos[0].filename}`}
            sx={{ width: 56, height: 56 }}
          />
          <Typography variant="body2" pl={1.5}>
            {params.row.product.name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "desc",
      headerName: "Descrição",
      flex: 4,
      minWidth: 150,
    },
    {
      field: "type",
      headerName: "Tipo",
      width: 100,
      type: "singleSelect",
      valueOptions: ["Entrada", "Saida"],
    },
    {
      field: "quantity",
      headerName: "Quantidade",
      width: 100,
      type: "number",
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await productHistoricService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <DataGrid
      rows={data}
      columns={columns}
      loading={loadingData}
      rowHeight={60}
    />
  );
};

export default ProductHistoricView;
