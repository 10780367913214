import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import yup from "../../common/validator";
import UploadImage from "../../components/uploadImage";
import { useDispatch } from "react-redux";
import slidesService from "../../services/slides";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";

const validation = {
  slides: yup.array().min(1),
};

const SlidesView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const slidesWatch = watch("slides");

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      const { data } = await slidesService.getAll();
      setValue("slides", data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, setValue]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      const formData = new FormData();
      data.slides.forEach((file) => {
        if (file.id) {
          formData.append("file", JSON.stringify(file));
        } else {
          if (file.fileUrl) URL.revokeObjectURL(file.fileUrl);
          formData.append("file", file);
        }
      });
      await slidesService.create(formData);
      enqueueSnackbar("Salvo com sucesso", { variant: "success" });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UploadImage
                control={control}
                name="slides"
                file={slidesWatch}
                setValue={setValue}
                isMultiple
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button type="submit">Salvar</Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default SlidesView;
