import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { getErrorMessage, moneyFormatter } from "../../utils";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import api from "../../common/api";
import logo from "../../assets/images/vetline.png";

const FinanceView = () => {
  const [data, setData] = useState({
    totalCard: "0",
    totalPix: "0",
    totalBoleto: "0",
  });
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const dateNow = new Date();
  const [valueDate, setValueDate] = useState({
    start: moment(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)),
    end: moment(),
  });
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [anchorElStartDate, setAnchorElStartDate] = useState(null);
  const [anchorElEndDate, setAnchorElEndDate] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openDateStart, setOpenDateStart] = useState(false);
  const [openDateEnd, setOpenDateEnd] = useState(false);

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await api.get(
        `/report/finance/${valueDate.start.toISOString()}/${valueDate.end.toISOString()}`
      );
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar, valueDate.end, valueDate.start]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Container maxWidth="lg" sx={{ displayPrint: "none" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          marginBottom={4}
        >
          <Grid item>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h5" component="h1" lineHeight={1.55}>
                  Financeiro - Análise
                </Typography>
              </Grid>
              <Grid item>
                <Box display="flex">
                  <Typography>de</Typography>
                  <Box
                    onClick={(event) => {
                      event.preventDefault();
                      setAnchorElStartDate(event.currentTarget);
                      setOpenDateStart(true);
                    }}
                    sx={{ userSelect: "none", px: 0.5 }}
                  >
                    <Typography
                      fontWeight={900}
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      })}
                    >
                      {valueDate.start.format("DD/MM/YYYY")}
                    </Typography>
                  </Box>

                  <Typography>até</Typography>
                  <Box
                    onClick={(event) => {
                      event.preventDefault();
                      setAnchorElEndDate(event.currentTarget);
                      setOpenDateEnd(true);
                    }}
                    sx={{ userSelect: "none", pl: 0.5 }}
                  >
                    <Typography
                      fontWeight={900}
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      })}
                    >
                      {valueDate.end.format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              endIcon={<KeyboardArrowDownIcon />}
              onClick={(event) => {
                setAnchorElMenu(event.currentTarget);
                setOpenMenu(true);
              }}
              sx={(theme) => ({
                [theme.breakpoints.down("xs")]: {
                  padding: "4px 10px",
                  fontSize: "0.8125rem",
                },
              })}
            >
              Baixar
            </Button>
          </Grid>
        </Grid>

        <Box
          my={1.5}
          display="flex"
          gap={2}
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Card elevation={5} sx={{ width: 210, height: 150 }}>
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography textAlign="center">Vendas em Cartões</Typography>
                <Divider flexItem />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <Typography fontSize="large" fontWeight="bold">
                        {moneyFormatter(data.totalCard)}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card elevation={5} sx={{ width: 210, height: 150 }}>
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography textAlign="center">Vendas no Pix</Typography>
                <Divider flexItem />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <Typography fontWeight="bold" fontSize="large">
                        {moneyFormatter(data.totalPix)}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card elevation={5} sx={{ width: 210, height: 150 }}>
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography textAlign="center">Vendas em Boleto</Typography>
                <Divider flexItem />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <Typography fontWeight="bold" fontSize="large">
                        {moneyFormatter(data.totalBoleto)}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>

      <Menu
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        sx={{ displayPrint: "none" }}
      >
        <MenuItem
          onClick={() => {
            setOpenMenu(false);
            window.print();
          }}
        >
          PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenMenu(false);
            window.open(
              `${
                process.env.REACT_APP_URL_API
              }/download/finance/${valueDate.start.toISOString()}/${valueDate.end.toISOString()}/${sessionStorage.getItem(
                "vl_token"
              )}/0`,
              "_blank"
            );
          }}
        >
          Excel
        </MenuItem>
      </Menu>

      <LocalizationProvider
        dateAdapter={AdapterMoment}
        sx={{ displayPrint: "none" }}
      >
        <DatePicker
          renderInput={() => null}
          open={openDateStart}
          onClose={() => setOpenDateStart(false)}
          value={valueDate.start}
          onChange={(newValue) =>
            setValueDate((oldValue) => ({ ...oldValue, start: newValue }))
          }
          PopperProps={{ anchorEl: anchorElStartDate }}
          views={["year", "month", "day"]}
          maxDate={valueDate.end}
        />
        <DatePicker
          renderInput={() => null}
          open={openDateEnd}
          onClose={() => setOpenDateEnd(false)}
          value={valueDate.end}
          onChange={(newValue) =>
            setValueDate((oldValue) => ({ ...oldValue, end: newValue }))
          }
          PopperProps={{ anchorEl: anchorElEndDate }}
          views={["year", "month", "day"]}
          minDate={valueDate.start}
        />
      </LocalizationProvider>

      <Container
        disableGutters
        sx={{ display: "none", displayPrint: "initial" }}
      >
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            backgroundColor: "background.paper",
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            display: "none",
            displayPrint: "initial",
          }}
        >
          <Toolbar sx={{ height: (theme) => theme.headerHeight }}>
            <img src={logo} alt="Logo da Vetline" height={35} />

            <Box flexGrow={1} />

            <Typography variant="body1" color="black" ml={1}>
              Financeiro - Análise (de {valueDate.start.format("DD/MM/YYYY")}{" "}
              até {valueDate.end.format("DD/MM/YYYY")})
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container spacing={2} direction="column">
          <Grid item>
            <Card>
              <CardContent style={{ paddingBottom: 16 }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography fontWeight={500}>
                      Vendas no Cartões:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {moneyFormatter(data.totalCard)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={500}>
                      Vendas no Pix:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {moneyFormatter(data.totalPix)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={500}>
                      Vendas no Boleto:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {moneyFormatter(data.totalBoleto)}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FinanceView;
