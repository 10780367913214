import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../common/validator";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage, moneyFormatter } from "../../utils";
import saleBankSlipService from "../../services/saleBankSlip";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { DeleteForeverOutlined } from "@mui/icons-material";

const validation = {
  name: yup.string().required(),
  type: yup.string().required(),
  payments: yup.array(),
};

const newPaymentValidation = {
  installments: yup.number().required().positive(),
  desc: yup.string().required(),
  amount: yup.number().required().positive(),
};

const SaleBankSlipFormView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const typePayment = watch("type");
  const payments = watch("payments");
  const [open, setOpen] = useState(false);
  const newPaymentForm = useForm({
    resolver: yupResolver(yup.object().shape(newPaymentValidation)),
  });

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    newPaymentForm.reset();
  };

  const getData = useCallback(async () => {
    if (id) {
      try {
        dispatch(startLoading());
        const { data } = await saleBankSlipService.get(id);
        reset(data);
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: "error" });
        navigate("..");
      } finally {
        dispatch(endLoading());
      }
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      id
        ? await saleBankSlipService.update(data)
        : await saleBankSlipService.create(data);
      enqueueSnackbar(`${id ? "Edição" : "Cadastro"} realizado com sucesso`, {
        variant: "success",
      });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const addPayment = (data) => {
    if (payments?.length) {
      setValue("payments", [...payments, data]);
    } else {
      setValue("payments", [data]);
    }
    handleClose();
  };

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="name"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Nome"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="type"
                  defaultValue="À vista"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error}>
                      <FormLabel>Opções de Pagamento</FormLabel>
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="À vista"
                          control={<Radio />}
                          label="À vista"
                        />
                        <FormControlLabel
                          value="Personalizado"
                          control={<Radio />}
                          label="Personalizado"
                        />
                      </RadioGroup>
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {typePayment === "Personalizado" ? (
                <Grid item xs={12}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 150, width: "15%" }}>
                          Nº de Parcelas
                        </TableCell>
                        <TableCell sx={{ minWidth: 150, width: "60%" }}>
                          Descrição
                        </TableCell>
                        <TableCell sx={{ minWidth: 150, width: "20%" }}>
                          Valor do Pedido
                        </TableCell>
                        <TableCell
                          sx={{ minWidth: 80, width: "5%" }}
                          align="center"
                        >
                          Ação
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments?.length ? (
                        payments.map((row, index) => (
                          <TableRow>
                            <TableCell>{row.installments}</TableCell>
                            <TableCell>{row.desc}</TableCell>
                            <TableCell>{moneyFormatter(row.amount)}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                color="error"
                                onClick={() => {
                                  dispatch(startLoading());
                                  const newArray = [...payments];
                                  newArray.splice(index, 1);
                                  setValue("payments", newArray);
                                  dispatch(endLoading());
                                }}
                              >
                                <DeleteForeverOutlined />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            Nenhuma linha
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
          <CardActions>
            {typePayment === "Personalizado" ? (
              <Button onClick={handleClickOpen}>
                Adicionar Forma de Pagamento
              </Button>
            ) : null}
            <Box flex="1" />
            <Button component={Link} to=".." color="error">
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </CardActions>
        </form>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <form onSubmit={newPaymentForm.handleSubmit(addPayment)}>
          <DialogTitle>Adicionar Forma de Pagamento</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={newPaymentForm.control}
                  name="installments"
                  defaultValue={undefined}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <NumberFormat
                      autoFocus
                      customInput={TextField}
                      label="Nº de Parcelas"
                      fullWidth
                      allowNegative={false}
                      variant="standard"
                      error={!!error}
                      helperText={error?.message}
                      onValueChange={(value) => onChange(value.floatValue)}
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={newPaymentForm.control}
                  name="amount"
                  defaultValue={undefined}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <NumberFormat
                      customInput={TextField}
                      label="Valor do Pedido"
                      fullWidth
                      allowNegative={false}
                      variant="standard"
                      prefix="R$ "
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      error={!!error}
                      helperText={error?.message}
                      onValueChange={(value) => onChange(value.floatValue)}
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={newPaymentForm.control}
                  name="desc"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Descrição"
                      variant="standard"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Cancelar
            </Button>
            <Button type="submit">Adicionar</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default SaleBankSlipFormView;
