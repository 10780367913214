import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import yup from "../../common/validator";
import authService from "../../services/auth";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import logo from "../../assets/images/vetline.png";
import dog1 from "../../assets/images/dog1.png";
import dog2 from "../../assets/images/dog2.png";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { WarningRounded, CheckRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";

const validation = {
  newPassword: yup.string().trim().required(),
  newPasswordConfirm: yup.string().trim().required(),
};

const UpdatePasswordView = () => {
  const { token } = useParams();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [recoveryId, setRecoveryId] = useState();
  const [invalidToken, setInvalidToken] = useState(false);
  const [successRecover, setSuccessRecover] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const verifyToken = async () => {
      if (token) {
        try {
          dispatch(startLoading());
          const { data } = await authService.verifyToken(token);
          setRecoveryId(data.recoveryId);
        } catch {
          navigate("not-found", { replace: true });
        } finally {
          dispatch(endLoading());
        }
      } else {
        navigate("not-found", { replace: true });
      }
    };

    verifyToken();
  }, [dispatch, navigate, token]);

  const onSubmit = async (values) => {
    if (values.newPassword === values.newPasswordConfirm) {
      try {
        dispatch(startLoading());
        if (recoveryId) {
          await authService.updatePassword({
            recoveryId,
            newPassword: values.newPassword,
          });
          setSuccessRecover(true);
          setTimeout(() => {
            navigate("/auth");
          }, 3000);
        } else {
          throw new Error("Not found recover id");
        }
      } catch (err) {
        setInvalidToken(true);
        enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      } finally {
        dispatch(endLoading());
      }
    }
  };

  return (
    <Container
      disableGutters
      maxWidth={false}
      component="main"
      sx={{ height: "100vh" }}
    >
      <Box
        bgcolor="#a0cd51"
        height="100vh"
        width="50%"
        position="absolute"
        left={0}
        zIndex={-1}
      />
      <Box
        bgcolor="#ea4434"
        height="100vh"
        width="50%"
        position="absolute"
        right={0}
        zIndex={-1}
      />

      <Box position="absolute" left={"45.5%"} top="50vh">
        <img src={dog1} alt="dog1" width={250} />
      </Box>
      <Box position="absolute" right="0" top="0">
        <img src={dog2} alt="dog2" width={300} />
      </Box>

      <Box
        display={"flex"}
        alignItems="center"
        justifyContent="flex-end"
        height="100vh"
      >
        <Box
          bgcolor="#fff"
          width="600px"
          padding={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          borderRadius={1}
          boxShadow={15}
        >
          <Box
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <img src={logo} alt="Logo da Vetline" width={150} />
            <Divider color="#ea4434" orientation="vertical" flexItem />
            <Typography
              fontSize={24}
              color="#ea4434"
              position="relative"
              top={6}
            >
              Gestão
            </Typography>
          </Box>

          {!invalidToken && !successRecover ? (
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: 350 }}>
              <Grid container direction="column" spacing={1} pt={2}>
                <Grid item container direction="column">
                  <Grid item>
                    <Controller
                      name="newPassword"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          label="Nova senha"
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          error={!!error}
                          helperText={error?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showPassword}
                          onChange={() => setShowPassword(!showPassword)}
                          color="primary"
                        />
                      }
                      label="Mostrar senha"
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <Controller
                      name="newPasswordConfirm"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          label="Confirmar nova senha"
                          fullWidth
                          type={showPasswordConfirm ? "text" : "password"}
                          error={!!error}
                          helperText={error?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showPasswordConfirm}
                          onChange={() =>
                            setShowPasswordConfirm(!showPasswordConfirm)
                          }
                          color="primary"
                        />
                      }
                      label="Mostrar senha"
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Mudar Senha
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : null}

          {invalidToken ? (
            <Box
              mt={1}
              width={1}
              p={2}
              borderRadius={1}
              display="flex"
              alignItems="center"
              sx={{ backgroundColor: "#ffe4e6" }}
            >
              <WarningRounded
                sx={{ marginRight: 1.5, fontSize: 40 }}
                color="error"
              />
              <Typography variant="body1">
                Falha no processo. Por favor, tente novamente mais tarde!
              </Typography>
            </Box>
          ) : null}

          {successRecover ? (
            <Box
              mt={1}
              width={1}
              p={2}
              borderRadius={1}
              display="flex"
              alignItems="center"
              sx={{ backgroundColor: "#d1fae5" }}
            >
              <CheckRounded
                sx={{ marginRight: 1.5, fontSize: 40 }}
                color="success"
              />
              <Typography variant="body1">
                Senha alterada com sucesso!
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Box width="45%" padding={4} position="relative" bottom={40}>
          <Typography
            color="white"
            fontSize="1.5rem"
            textAlign="center"
            mb={10}
          >
            Bem-vindo(a) ao Vet line gestão app!
          </Typography>
          <Typography
            color="white"
            textAlign="center"
            fontWeight={900}
            fontSize="3rem"
          >
            Mais um ótimo dia para vendas!
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default UpdatePasswordView;
