import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import { ptBR as ptBrGrid } from "@mui/x-data-grid";

const theme = createTheme(
  {
    palette: {
      background: {
        default: "#f5f5f5",
        paper: "#fff",
      },
      primary: {
        main: "#656565",
      },
    },
    sidebarWidth: 290,
    headerHeight: 64,
  },
  ptBR,
  ptBrGrid
);

export default responsiveFontSizes(theme);
