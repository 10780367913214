import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import HeaderView from "../../components/headerView";

const CrudLayout = ({ title, titleForm, showButton = false }) => {
  const [isForm, setIsForm] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (/form/.test(location.pathname)) setIsForm(true);
    else setIsForm(false);
  }, [location.pathname]);

  return (
    <Container maxWidth="lg">
      <HeaderView
        title={titleForm && isForm ? titleForm : title}
        buttonTo={isForm ? undefined : "form"}
        showButton={showButton}
      />

      <Outlet />
    </Container>
  );
};

export default CrudLayout;
