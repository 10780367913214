import api from "../common/api";

const getAll = () => api.get("/category");

const get = (id) => api.get(`/category/${id}`);

const create = (data) => api.post("/category", data);

const update = (data) => api.put(`/category/${data.id}`, data);

const remove = (id) => api.delete(`/category/${id}`);

const categoryService = { getAll, get, create, update, remove };

export default categoryService;
