import api from "../common/api";

const getAll = (id) => api.get(`restricao/all/${id}`);

const get = (id) => api.get(`restricao/one/${id}`);

const create = (data) => api.post("restricao", data);

const update = (id, data) => api.put(`restricao/${id}`, data);

const remove = (id) => api.delete(`restricao/${id}`);

const restricaoService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default restricaoService;
