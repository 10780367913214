import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import TabPanel from "../../components/tabPanel";
import { useDispatch } from "react-redux";
import { getErrorMessage, moneyFormatter } from "../../utils";
import paymentHistoricService from "../../services/paymentHistoric";
import moment from "moment";
import { OpenInNewOutlined } from "@mui/icons-material";
import DataGrid from "../../components/dataGrid";
import { endLoading, startLoading } from "../../store/loadingSlice";

const HistoricPaymentView = () => {
  const [valueTab, setValueTab] = useState(0);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [boleto, setBoleto] = useState([]);
  const [credit, setCredit] = useState([]);
  const [debit, setDebit] = useState([]);
  const [pix, setPix] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState();
  const columnsBoleto = [
    {
      field: "dateCreated",
      headerName: "Data e Hora",
      minWidth: 150,
      flex: 1,
      type: "dateTime",
      valueFormatter: ({ value }) =>
        moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "user",
      headerName: "Cliente",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        params.row?.user.isPJ
          ? params.row.user.corporateName_firstName
          : `${params.row.user.corporateName_firstName} ${params.row.user.fantasyName_lastName}`,
    },
    {
      field: "reference",
      headerName: "Referência",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "Preço",
      flex: 1,
      minWidth: 150,
      type: "number",
      valueFormatter: ({ value }) => moneyFormatter(value),
    },
    {
      field: "action",
      headerName: "Ações",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => (
        <IconButton
          mx={2}
          onClick={() => {
            setDialogData(params.row);
            setOpen(true);
          }}
        >
          <OpenInNewOutlined />
        </IconButton>
      ),
    },
  ];
  const columnsCredit = [
    {
      field: "dateCreated",
      headerName: "Data e Hora",
      minWidth: 150,
      flex: 1,
      type: "dateTime",
      valueFormatter: ({ value }) =>
        moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "user",
      headerName: "Cliente",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        params.row?.user.isPJ
          ? params.row.user.corporateName_firstName
          : `${params.row.user.corporateName_firstName} ${params.row.user.fantasyName_lastName}`,
    },
    {
      field: "reference",
      headerName: "Referência",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "Preço",
      flex: 1,
      minWidth: 150,
      type: "number",
      valueFormatter: ({ value }) => moneyFormatter(value),
    },
    {
      field: "installment",
      headerName: "Parcelamento",
      flex: 1,
      minWidth: 150,
      type: "number",
      valueFormatter: ({ value }) => `${value}x`,
    },
  ];
  const columns = [
    {
      field: "dateCreated",
      headerName: "Data e Hora",
      minWidth: 150,
      flex: 1,
      type: "dateTime",
      valueFormatter: ({ value }) =>
        moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "user",
      headerName: "Cliente",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        params.row?.user.isPJ
          ? params.row.user.corporateName_firstName
          : `${params.row.user.corporateName_firstName} ${params.row.user.fantasyName_lastName}`,
    },
    {
      field: "reference",
      headerName: "Referência",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "Preço",
      flex: 1,
      minWidth: 150,
      type: "number",
      valueFormatter: ({ value }) => moneyFormatter(value),
    },
  ];

  const tabToggle = (event, newValue) => {
    setValueTab(newValue);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await paymentHistoricService.getHitoric();
      setBoleto(data.boleto);
      setCredit(data.credit);
      setDebit(data.debit);
      setPix(data.pix);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleClose = () => {
    setOpen(false);
    getData();
  };

  return (
    <>
      <Box
        position="static"
        bgcolor="white"
        sx={{
          borderRadius: "4px 4px 0 0",
          boxShadow: (theme) => theme.shadows[1],
        }}
      >
        <Tabs value={valueTab} onChange={tabToggle} variant="scrollable">
          <Tab label="Boleto Bancário" {...a11yProps(0)} />
          <Tab label="Cartão de Crédito" {...a11yProps(1)} />
          <Tab label="Cartão de Débito" {...a11yProps(2)} />
          <Tab label="PIX" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <TabPanel value={valueTab} index={0}>
        <CardContent>
          <DataGrid rows={boleto} columns={columnsBoleto} loading={loading} />
        </CardContent>
      </TabPanel>

      <TabPanel value={valueTab} index={1}>
        <CardContent>
          <DataGrid rows={credit} columns={columnsCredit} loading={loading} />
        </CardContent>
      </TabPanel>

      <TabPanel value={valueTab} index={2}>
        <CardContent>
          <DataGrid rows={debit} columns={columns} loading={loading} />
        </CardContent>
      </TabPanel>

      <TabPanel value={valueTab} index={3}>
        <CardContent>
          <DataGrid rows={pix} columns={columns} loading={loading} />
        </CardContent>
      </TabPanel>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Boletos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Parcelamento utilizado:{" "}
            {dialogData?.installment
              ? `${dialogData.installment}x - ${dialogData.boletoDesc}`
              : "À vista"}
          </DialogContentText>

          {dialogData?.installment ? (
            <>
              {Array.from({ length: dialogData.installment }).map((_, i) => (
                <>
                  <Stack
                    mt={2}
                    mb={i + 1 === dialogData.installment ? 0 : 2}
                    direction="row"
                    justifyContent="space-between"
                    alignContent="center"
                  >
                    <Typography>
                      Boleto Nº {i + 1} - Valor:{" "}
                      {moneyFormatter(
                        dialogData?.amount / dialogData.installment
                      )}{" "}
                      - Status:{" "}
                      {dialogData?.boletoInstallmentPaid >= i + 1
                        ? "Pago"
                        : "Não pago"}
                    </Typography>
                    {dialogData.boletoInstallmentPaid === i ? (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={async () => {
                          try {
                            dispatch(startLoading());
                            await paymentHistoricService.update(dialogData?.id);
                            setDialogData({
                              ...dialogData,
                              boletoInstallmentPaid: i + 1,
                            });
                          } catch (err) {
                            enqueueSnackbar(getErrorMessage(err), {
                              variant: "error",
                            });
                          } finally {
                            dispatch(endLoading());
                          }
                        }}
                      >
                        Pago
                      </Button>
                    ) : null}
                  </Stack>
                  {i + 1 === dialogData.installment ? null : <Divider />}
                </>
              ))}
            </>
          ) : (
            <Stack
              mt={2}
              direction="row"
              justifyContent="space-between"
              alignContent="center"
            >
              <Typography>
                Boleto Nº 1 - Valor: {moneyFormatter(dialogData?.amount)} -
                Status:{" "}
                {dialogData?.boletoInstallmentPaid ? "Pago" : "Não pago"}
              </Typography>
              {dialogData?.boletoInstallmentPaid === 0 ? (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={async () => {
                    try {
                      dispatch(startLoading());
                      await paymentHistoricService.update(dialogData?.id);
                      setDialogData({
                        ...dialogData,
                        boletoInstallmentPaid: 1,
                      });
                    } catch (err) {
                      enqueueSnackbar(getErrorMessage(err), {
                        variant: "error",
                      });
                    } finally {
                      dispatch(endLoading());
                    }
                  }}
                >
                  Pago
                </Button>
              ) : null}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default HistoricPaymentView;
