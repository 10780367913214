import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../common/validator";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import productHistoricService from "../../services/productHistoric";

const validation = {
  quantity: yup.number().positive().integer().required(),
  desc: yup.string(),
  isEntry: yup.bool().required(),
};

const StockFormView = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      await productHistoricService.create({ ...data, productId: id });
      enqueueSnackbar("Operação realizada com sucesso", { variant: "success" });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="quantity"
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Quantidade de entrada/saída"
                    type="number"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="desc"
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Descrição"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="isEntry"
                defaultValue={true}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <FormLabel>Tipo do registro</FormLabel>
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Entrada"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Saída"
                      />
                    </RadioGroup>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button component={Link} to=".." color="error">
            Cancelar
          </Button>
          <Button type="submit">Salvar</Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default StockFormView;
