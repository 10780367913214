import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import yup from "../../common/validator";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import logo from "../../assets/images/vetline.png";
import dog1 from "../../assets/images/dog1.png";
import dog2 from "../../assets/images/dog2.png";
import authService from "../../services/auth";
import { useNavigate } from "react-router-dom";

const validation = {
  email: yup.string().trim().email().required(),
};

const RecoverPasswordView = () => {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async ({ email }) => {
    try {
      dispatch(startLoading());
      await authService.recoverPassword(email);
      enqueueSnackbar(
        "Verifique seu e-mail para continuar o processo de recuperação.",
        { variant: "success" }
      );
      navigate("..", { replace: true });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Container
      disableGutters
      maxWidth={false}
      component="main"
      sx={{ height: "100vh" }}
    >
      <Box
        bgcolor="#a0cd51"
        height="100vh"
        width="50%"
        position="absolute"
        left={0}
        zIndex={-1}
      />
      <Box
        bgcolor="#ea4434"
        height="100vh"
        width="50%"
        position="absolute"
        right={0}
        zIndex={-1}
      />

      <Box position="absolute" left={"45.5%"} top="42vh">
        <img src={dog1} alt="dog1" width={250} />
      </Box>
      <Box position="absolute" right="0" top="0">
        <img src={dog2} alt="dog2" width={300} />
      </Box>

      <Box
        display={"flex"}
        alignItems="center"
        justifyContent="flex-end"
        height="100vh"
      >
        <Box
          bgcolor="#fff"
          width="600px"
          padding={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
          boxShadow={15}
        >
          <Box
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <img src={logo} alt="Logo da Vetline" width={150} />
            <Divider color="#ea4434" orientation="vertical" flexItem />
            <Typography
              fontSize={24}
              color="#ea4434"
              position="relative"
              top={6}
            >
              Gestão
            </Typography>
          </Box>

          <Typography fontSize={24} fontWeight={900} mb={2}>
            Recuperar senha
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} style={{ width: 350 }}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="E-mail"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Recuperar Senha
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>

        <Box width="45%" padding={4} position="relative" bottom={40}>
          <Typography
            color="white"
            fontSize="1.5rem"
            textAlign="center"
            mb={10}
          >
            Bem-vindo(a) ao Vet line gestão app!
          </Typography>
          <Typography
            color="white"
            textAlign="center"
            fontWeight={900}
            fontSize="3rem"
          >
            Mais um ótimo dia para vendas!
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default RecoverPasswordView;
