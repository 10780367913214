import {
  Box,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import sidebarItems from "../../common/sidebarItems";
import { selectDrawer, toggleDrawer } from "../../store/drawerSlice";
import SidebarGroup from "./sidebarGroup";

const Sidebar = () => {
  const theme = useTheme();
  const matchScreen = useMediaQuery(theme.breakpoints.up("md"));
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const drawer = useSelector(selectDrawer);
  const dispatch = useDispatch();

  const drawerList = (
    <>
      <Box p={2}>
        {sidebarItems.map((item, i) => {
          if (item.type === "group") {
            return (
              <SidebarGroup
                key={`${item.type}-${item.title}`}
                group={item}
                isLast={i === sidebarItems.length - 1}
              />
            );
          }
          return (
            <Typography
              key={`${item.type}-${item.title}`}
              variant="body2"
              color="error"
              align="center"
            >
              Group Error
            </Typography>
          );
        })}
      </Box>
    </>
  );

  return (
    <Box
      component="nav"
      sx={(theme) => ({
        [theme.breakpoints.up("md")]: {
          width: theme.sidebarWidth,
          flexShrink: 0,
        },
        displayPrint: "none",
      })}
    >
      <Drawer
        container={container}
        variant={matchScreen ? "persistent" : "temporary"}
        anchor="left"
        open={drawer}
        onClose={() => dispatch(toggleDrawer())}
        ModalProps={{ keepMounted: true }}
        sx={(theme) => ({
          "& .MuiDrawer-paper": {
            backgroundColor: theme.palette.background.paper,
            width: theme.sidebarWidth,
            borderTop: `1px solid ${theme.palette.divider}`,
            [theme.breakpoints.up("md")]: {
              top: theme.headerHeight,
              height: `calc(100vh - ${theme.headerHeight}px)`,
            },
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              borderRadius: theme.shape.borderRadius,
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.25)",
            },
          },
        })}
      >
        {drawerList}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
