import api from "../common/api";

const getAll = () => api.get("universidade/noticia");

const get = (id) => api.get(`universidade/noticia/${id}`);

const create = () => api.post("universidade/noticia");

const update = (id, data) => api.put(`universidade/noticia/${id}`, data);

const addImagem = (id, data) =>
  api.put(`universidade/noticia/${id}/imagem`, data);

const remove = (id) => api.delete(`universidade/noticia/${id}`);

const universidadeNoticiasService = {
  getAll,
  get,
  create,
  update,
  remove,
  addImagem,
};

export default universidadeNoticiasService;
