import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage, phoneFormatter } from "../../utils";
import userService from "../../services/user";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

const UserPJView = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const columns = [
    {
      field: "corporateName_firstName",
      headerName: "Razão Social",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "fantasyName_lastName",
      headerName: "Nome Fantasia",
      flex: 1,
      minWidth: 150,
    },
    { field: "cnpj_cpf", headerName: "CNPJ", flex: 1, minWidth: 150 },
    { field: "email", headerName: "E-mail", flex: 1, minWidth: 150 },
    {
      field: "phone1",
      headerName: "Telefone 1",
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value }) => (value ? phoneFormatter(value) : ""),
    },
    {
      field: "phone2",
      headerName: "Telefone 2",
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value }) => (value ? phoneFormatter(value) : ""),
    },
    {
      field: "active",
      headerName: "Ativo",
      flex: 1,
      minWidth: 150,
      type: "boolean",
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await userService.getAll("pj");
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickDelete = async (id) => {
    try {
      dispatch(startLoading());
      await userService.remove(id);
      enqueueSnackbar("Remoção realizada com sucesso", {
        variant: "success",
      });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onImport = async () => {
    const sheet = document.getElementById("sheetField").value;
    const file = document.getElementById("excelFile").files[0];

    if (!sheet) {
      return enqueueSnackbar("Informe o nome da página", {
        variant: "warning",
      });
    } else if (!file) {
      return enqueueSnackbar("Selecione um arquivo para importar", {
        variant: "warning",
      });
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("sheet", sheet);

    try {
      dispatch(startLoading());
      const { data } = await userService.importPJ(formData);
      setOpenDialog(false);
      enqueueSnackbar(data.msg, { variant: "success" });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
      getData();
    }
  };

  return (
    <>
      <DataGrid
        rows={data}
        columns={columns}
        editTo={(id) => `form/${id}`}
        onClickDelete={onClickDelete}
        loading={loadingData}
        exportLink={`${
          process.env.REACT_APP_URL_API
        }/download/user/${sessionStorage.getItem("vl_token")}/0`}
        onImport={() => setOpenDialog(true)}
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Importar clientes de um arquivo Excel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Infome o nome da página dentro do Excel que contém os dados dos
            clientes a ser importado.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Nome da página"
            fullWidth
            variant="standard"
            id="sheetField"
          />
          <Box mt={1}>
            <input
              type="file"
              style={{ width: "100%" }}
              multiple={false}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="excelFile"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
          <Button onClick={onImport}>Importar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserPJView;
