import api from "../common/api";

const getAll = () => api.get("universidade/curso");

const get = (id) => api.get(`universidade/curso/${id}`);

const create = () => api.post("universidade/curso");

const update = (id, data) => api.put(`universidade/curso/${id}`, data);

const addVideo = (id, data) => api.put(`universidade/curso/${id}/video`, data);

const addArquivo = (id, data) =>
  api.put(`universidade/curso/${id}/arquivo`, data);

const remove = (id) => api.delete(`universidade/curso/${id}`);

const removeVideo = (id, idVideo) =>
  api.delete(`universidade/curso/${id}/video/${idVideo}`);

const removeArquivo = (id, idArquivo) =>
  api.delete(`universidade/curso/${id}/arquivo/${idArquivo}`);

const universidadeCursoService = {
  getAll,
  get,
  create,
  update,
  addVideo,
  addArquivo,
  remove,
  removeVideo,
  removeArquivo,
};

export default universidadeCursoService;
