import api from "../common/api";

const getAll = () => api.get("universidade/video");

const get = (id) => api.get(`universidade/video/${id}`);

const create = (data) => api.post("universidade/video", data);

const update = (data) => api.put(`universidade/video/${data.id}`, data);

const remove = (id) => api.delete(`universidade/video/${id}`);

const universidadeVideoService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default universidadeVideoService;
