import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormLabel,
  FormControl,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../common/validator";
import { useDispatch } from "react-redux";
import permissionService from "../../services/permission";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";

const validation = {
  name: yup.string().trim().required(),
};

const RolePermissionFormView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [permissions, setPermissions] = useState([]);
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const [role, permission] = await Promise.all([
          permissionService.getRole(id),
          permissionService.getAllPermission(),
        ]);
        reset(role.data);
        setPermissions(permission.data);
      } else {
        const { data } = await permissionService.getAllPermission();
        setPermissions(data);
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..", { replace: true });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      id
        ? await permissionService.updateRole(data)
        : await permissionService.createRole(data);
      enqueueSnackbar(`${id ? "Edição" : "Cadastro"} realizado com sucesso`, {
        variant: "success",
      });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Nome"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Permissões</FormLabel>
                <FormGroup>
                  <Controller
                    control={control}
                    name="permissions"
                    defaultValue={[]}
                    render={({ field }) => (
                      <>
                        {permissions.map((item) => (
                          <FormControlLabel
                            key={item.id}
                            label={item.name}
                            control={
                              <Checkbox
                                value={item.id}
                                checked={field.value.some(
                                  (value) => value.id === item.id
                                )}
                                onChange={(event, checked) => {
                                  if (checked) {
                                    field.onChange([...field.value, item]);
                                  } else {
                                    field.onChange(
                                      field.value.filter(
                                        (value) =>
                                          value.id !== +event.target.value
                                      )
                                    );
                                  }
                                }}
                              />
                            }
                          />
                        ))}
                      </>
                    )}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button component={Link} to=".." color="error">
            Cancelar
          </Button>
          <Button type="submit">Salvar</Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default RolePermissionFormView;
