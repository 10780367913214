import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import yup from "../../common/validator";
import { Controller, useForm } from "react-hook-form";
import productService from "../../services/product";
import categoryService from "../../services/category";
import brandsService from "../../services/brand";
import promotionService from "../../services/promotion";
import NumberFormat from "react-number-format";
import DayHoursWeek from "../../components/dayHoursWeek";
import { Link, useNavigate } from "react-router-dom";

const validation = {
  products: yup.array().nullable(),
  categories: yup.array().nullable(),
  brands: yup.array().nullable(),
  percentage: yup
    .string()
    .required()
    .transform((currentValue) => {
      if (currentValue) {
        const newValue = currentValue.replace(/[^0-9,]/g, "").replace(",", ".");
        return newValue;
      }
      return "";
    }),
  promotionAvailability: yup.array(),
};

const PromotionFormView = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const { control, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const promotionAvailability = watch("promotionAvailability");
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      const [products, category, brands] = await Promise.all([
        productService.getAll(),
        categoryService.getAll(),
        brandsService.getAll(),
      ]);
      setProducts(products.data);
      setCategories(category.data);
      setBrands(brands.data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..");
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, navigate]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    if (promotionAvailability && promotionAvailability.length > 0) {
      try {
        dispatch(startLoading());
        await promotionService.create(data);
        enqueueSnackbar(`Produtos atualizados com sucesso`, {
          variant: "success",
        });
        navigate("..");
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      } finally {
        dispatch(endLoading());
      }
    } else {
      enqueueSnackbar("Selecione pelo menos 1 dia para aplicar a promoção", {
        variant: "error",
      });
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="products"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={products}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Produtos Vinculados"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="categories"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Categorias Vinculadas"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="brands"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={brands}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Fornecedores Vinculados"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="percentage"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <NumberFormat
                    customInput={TextField}
                    label="Porcentagem"
                    fullWidth
                    suffix="%"
                    decimalScale={2}
                    decimalSeparator=","
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <DayHoursWeek
                name="promotionAvailability"
                availability={promotionAvailability}
                setValue={setValue}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button component={Link} to=".." color="error">
            Cancelar
          </Button>
          <Button type="submit">Criar</Button>
        </CardActions>
      </form>
    </Card>
  );
};
export default PromotionFormView;
