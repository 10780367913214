import api from "../common/api";

const getAll = () => api.get("universidade/integracao/bi");

const get = (id) => api.get(`universidade/integracao/bi/${id}`);

const create = () => api.post("universidade/integracao/bi");

const update = (id, data) => api.put(`universidade/integracao/bi/${id}`, data);

const addVideo = (id, data) =>
  api.put(`universidade/integracao/bi/${id}/video`, data);

const addArquivo = (id, data) =>
  api.put(`universidade/integracao/bi/${id}/arquivo`, data);

const remove = (id) => api.delete(`universidade/integracao/bi/${id}`);

const removeVideo = (id, idVideo) =>
  api.delete(`universidade/integracao/bi/${id}/video/${idVideo}`);

const removeArquivo = (id, idArquivo) =>
  api.delete(`universidade/integracao/bi/${id}/arquivo/${idArquivo}`);

const universidadeIntegracaoBIService = {
  getAll,
  get,
  create,
  update,
  addVideo,
  addArquivo,
  remove,
  removeVideo,
  removeArquivo,
};

export default universidadeIntegracaoBIService;
