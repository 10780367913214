import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage, phoneFormatter } from "../../utils";
import contactsService from "../../services/contacts";

const ContactsView = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "setor",
      headerName: "Setor",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "firstName",
      headerName: "Nome",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        `${params.row.firstName} ${params.row.lastName || ""}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phone",
      headerName: "Telefone",
      minWidth: 150,
      valueFormatter: ({ value }) => (value ? phoneFormatter(value) : ""),
    },
    {
      field: "atividades",
      headerName: "Atividades",
      flex: 1,
      minWidth: 150,
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await contactsService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickDelete = async (id) => {
    try {
      dispatch(startLoading());
      await contactsService.remove(id);
      enqueueSnackbar("Remoção realizada com sucesso", {
        variant: "success",
      });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <DataGrid
      rows={data}
      columns={columns}
      editTo={(id) => `form/${id}`}
      onClickDelete={onClickDelete}
      loading={loadingData}
    />
  );
};

export default ContactsView;
