import api from "../common/api";

const getAllPermission = () => api.get("/permission");

const getAllRole = () => api.get("/permission/role");

const getRole = (id) => api.get(`/permission/role/${id}`);

const createRole = (data) => api.post("/permission/role", data);

const updateRole = (data) => api.put(`/permission/role/${data.id}`, data);

const removeRole = (id) => api.delete(`/permission/role/${id}`);

const permissionService = {
  getAllPermission,
  getAllRole,
  getRole,
  createRole,
  updateRole,
  removeRole,
};

export default permissionService;
