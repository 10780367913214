import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const AppView = () => {
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(null);

  useEffect(() => {
    if (searchParams.get("url")) {
      const open = window.open(searchParams.get("url"));
      if (open) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (isOpen) {
      window.open("about:blank", "_self");
      window.close();
    }
  }, [isOpen]);

  return (
    <>
      {isOpen === false ? (
        <Box
          sx={{
            p: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              window.open(searchParams.get("url"));
              setIsOpen(true);
            }}
            variant="contained"
          >
            Abrir aplicativo para continuar
          </Button>
        </Box>
      ) : null}
    </>
  );
};

export default AppView;
