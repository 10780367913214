import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleDrawer } from "../../store/drawerSlice";
import * as MaterialIcon from "@mui/icons-material";
import { createElement } from "react";

const SidebarItem = ({ item, level }) => {
  const resolved = useResolvedPath(item.url ? item.url : "");
  const match = useMatch({ path: resolved.pathname, end: true });
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const itemIcon = item.icon ? createElement(MaterialIcon[item.icon]) : null;

  const handleClose = () => {
    if (matchScreen) dispatch(toggleDrawer);
  };

  return (
    <ListItem
      button
      sx={{
        paddingLeft: `${level * 18}px`,
        marginBottom: "5px",
        alignItems: "flex-start",
        borderRadius: 1,
        paddingY: "8px",
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: (theme) =>
            level <= 1 ? theme.palette.action.hover : "transparent",
          color: "primary.main",
          "& svg": {
            color: "primary.main",
          },
        },
        "&.Mui-selected": {
          color: "primary.main",
          backgroundColor: level <= 1 ? "" : "transparent",
          "& svg": {
            color: "primary.main",
          },
        },
      }}
      component={Link}
      to={item.url ? item.url : "/"}
      selected={match && item.url ? true : false}
      onClick={handleClose}
    >
      <ListItemIcon
        sx={{
          minWidth: item.icon ? 28 : 18,
          marginY: "auto",
        }}
      >
        {item.iconSvg ? (
          <SvgIcon component={item.iconSvg} inheritViewBox sx={{ mr: 0.5 }} />
        ) : (
          itemIcon
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" component="p" color="inherit">
            {item.title}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default SidebarItem;
