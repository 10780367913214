import {
  LogoutOutlined,
  MenuOutlined,
  NotificationsOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Hidden,
  IconButton,
  Popover,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/vetline.png";
import api from "../../common/api";
import { selectUser, signOut } from "../../store/authSlice";
import { toggleDrawer } from "../../store/drawerSlice";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../utils";
import moment from "moment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 3,
    top: 3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [notification, setNotification] = useState(0);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const SECONDS_MS = 15000;

  useEffect(() => {
    const interval = setInterval(
      (function notificationCount() {
        api
          .get("/notification/count")
          .then((res) => setNotification(parseInt(res.data.count, 10)));
        return notificationCount;
      })(),
      SECONDS_MS
    );

    return () => clearInterval(interval);
  }, []);

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await api.get("/notification");
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  const handleClick = (event) => {
    getData();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateRead = async (id) => {
    try {
      await api.patch("/notification", { id });
      setNotification((oldValue) => oldValue - 1);
    } catch {}
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        displayPrint: "none",
      }}
    >
      <Toolbar
        sx={{
          height: (theme) => theme.headerHeight,
        }}
      >
        <IconButton onClick={() => dispatch(toggleDrawer())} sx={{ mr: 1 }}>
          <MenuOutlined />
        </IconButton>

        <Box component={Link} to="/">
          <img src={logo} alt="Logo da Vetline" height={35} />
        </Box>

        <Box flexGrow={1} />

        <IconButton color="primary" onClick={handleClick}>
          <StyledBadge badgeContent={notification} color="error">
            <NotificationsOutlined />
          </StyledBadge>
        </IconButton>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box width={300}>
            {loadingData ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={150}
              >
                <CircularProgress />
              </Box>
            ) : data.length > 0 ? (
              <Stack p={1} gap={1}>
                {data.map((row, index) => (
                  <Card key={index} elevation={2}>
                    <CardContent sx={{ pb: "16px !important" }}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Typography fontSize="small">
                          {moment(row.dateCreated).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </Typography>
                        {row.read ? null : (
                          <Chip label="Novo" size="small" color="primary" />
                        )}
                      </Stack>
                      <Stack direction={"row"} alignItems="center">
                        <Typography flex="1">{row.desc}</Typography>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            navigate(row.link);
                            handleClose();
                            updateRead(row.id);
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
              </Stack>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={150}
              >
                <Typography>Não há notificações</Typography>
              </Box>
            )}
          </Box>
        </Popover>

        <Hidden smDown>
          <Divider orientation="vertical" variant="middle" flexItem />
        </Hidden>

        <Hidden smDown>
          <Typography variant="body1" color="black" ml={1}>
            Olá {user?.name}
          </Typography>
        </Hidden>

        <IconButton onClick={() => dispatch(signOut())} color="error">
          <LogoutOutlined />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
