import {
  AddOutlined,
  DeleteForeverOutlined,
  Download,
  EditOutlined,
  Upload,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  DialogActions,
  Button,
  Box,
  alpha,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  DataGrid as Datagrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useState } from "react";
import { Link } from "react-router-dom";

const DataGrid = ({
  rows,
  columns,
  loading = false,
  onClickDelete = undefined,
  editTo = undefined,
  viewTo = undefined,
  createTo = undefined,
  rowHeight = 52,
  showHeader = true,
  exportLink = undefined,
  onImport = undefined,
  onPageChange = undefined,
  initialPage = 0,
}) => {
  const [open, setOpen] = useState(false);
  const [idSelected, setIdSelected] = useState();

  const handleDialog = () => {
    setOpen(!open);
  };

  return (
    <>
      <Datagrid
        initialState={{
          pagination: {
            page: initialPage,
          },
        }}
        rows={rows}
        columns={columns}
        rowHeight={rowHeight}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          background: (theme) => theme.palette.background.paper,
          border: 0,
        }}
        components={
          showHeader
            ? {
                Toolbar: () => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    p={[0.5, 0.5, 0, 0.5]}
                  >
                    <div>
                      <GridToolbarFilterButton />
                      <GridToolbarColumnsButton />
                      {exportLink ? (
                        <>
                          <Button
                            startIcon={<Download />}
                            variant="text"
                            size="small"
                            color="primary"
                            sx={{
                              "&:hover": {
                                backgroundColor: (theme) =>
                                  alpha(
                                    theme.palette.info.main,
                                    theme.palette.action.hoverOpacity
                                  ),
                                "@media (hover: none)": {
                                  backgroundColor: "transparent",
                                },
                              },
                            }}
                            onClick={() => window.open(exportLink, "_blank")}
                          >
                            Exportar
                          </Button>
                        </>
                      ) : null}
                      {onImport ? (
                        <>
                          <Button
                            startIcon={<Upload />}
                            variant="text"
                            size="small"
                            color="primary"
                            sx={{
                              "&:hover": {
                                backgroundColor: (theme) =>
                                  alpha(
                                    theme.palette.info.main,
                                    theme.palette.action.hoverOpacity
                                  ),
                                "@media (hover: none)": {
                                  backgroundColor: "transparent",
                                },
                              },
                            }}
                            onClick={onImport}
                          >
                            Importar
                          </Button>
                        </>
                      ) : null}
                    </div>

                    <div>
                      {createTo && (
                        <Button
                          startIcon={<AddOutlined />}
                          variant="text"
                          size="small"
                          component={Link}
                          to={createTo}
                          color="info"
                          sx={{
                            "&:hover": {
                              backgroundColor: (theme) =>
                                alpha(
                                  theme.palette.info.main,
                                  theme.palette.action.hoverOpacity
                                ),
                              "@media (hover: none)": {
                                backgroundColor: "transparent",
                              },
                            },
                          }}
                        >
                          Novo
                        </Button>
                      )}
                      {idSelected && (
                        <>
                          {viewTo && (
                            <Button
                              startIcon={<VisibilityOutlined />}
                              variant="text"
                              size="small"
                              component={Link}
                              to={viewTo(+idSelected)}
                              color="primary"
                              sx={{
                                "&:hover": {
                                  backgroundColor: (theme) =>
                                    alpha(
                                      theme.palette.primary.main,
                                      theme.palette.action.hoverOpacity
                                    ),
                                  "@media (hover: none)": {
                                    backgroundColor: "transparent",
                                  },
                                },
                              }}
                            >
                              Visualizar
                            </Button>
                          )}
                          {editTo && (
                            <Button
                              startIcon={<EditOutlined />}
                              variant="text"
                              size="small"
                              component={Link}
                              to={editTo(+idSelected)}
                              color="info"
                              sx={{
                                "&:hover": {
                                  backgroundColor: (theme) =>
                                    alpha(
                                      theme.palette.info.main,
                                      theme.palette.action.hoverOpacity
                                    ),
                                  "@media (hover: none)": {
                                    backgroundColor: "transparent",
                                  },
                                },
                              }}
                            >
                              Editar
                            </Button>
                          )}
                          {onClickDelete && (
                            <Button
                              startIcon={<DeleteForeverOutlined />}
                              variant="text"
                              size="small"
                              onClick={handleDialog}
                              color="error"
                              sx={{
                                "&:hover": {
                                  backgroundColor: (theme) =>
                                    alpha(
                                      theme.palette.error.main,
                                      theme.palette.action.hoverOpacity
                                    ),
                                  "@media (hover: none)": {
                                    backgroundColor: "transparent",
                                  },
                                },
                              }}
                            >
                              Remover
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </Box>
                ),
              }
            : undefined
        }
        hideFooterSelectedRowCount
        loading={loading}
        disableSelectionOnClick={loading}
        autoHeight
        selectionModel={idSelected}
        onSelectionModelChange={(selection) => setIdSelected(selection[0])}
        onPageChange={onPageChange}
      />

      {onClickDelete && (
        <Dialog open={open} onClose={handleDialog}>
          <DialogContent>
            <DialogContentText color="textPrimary">
              Tem certeza que deseja prosseguir com a remoção?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialog} autoFocus>
              Não
            </Button>
            <Button
              onClick={() => {
                onClickDelete(idSelected ? +idSelected : 0);
                setIdSelected(undefined);
                handleDialog();
              }}
              color="error"
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DataGrid;
