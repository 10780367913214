import { AddOutlined, DeleteForeverOutlined } from "@mui/icons-material";
import { Box, FormHelperText, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";

const UploadImage = ({ isMultiple = false, control, name, file, setValue }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    maxFiles: isMultiple ? undefined : 1,
    multiple: isMultiple,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        if (isMultiple) {
          const newFiles = acceptedFiles.map((file) =>
            Object.assign(file, { fileUrl: URL.createObjectURL(file) })
          );
          setValue(name, [...file, ...newFiles]);
        } else {
          const file = Object.assign(acceptedFiles[0], {
            fileUrl: URL.createObjectURL(acceptedFiles[0]),
          });
          setValue(name, file);
        }
      } else if (!isMultiple && rejectedFiles.length > 1) {
        enqueueSnackbar("Máximo de 1 imagem", { variant: "error" });
      } else {
        enqueueSnackbar("Imagem inválida", { variant: "error" });
      }
    },
  });

  const generateThumbs = () => {
    if (file) {
      if (Array.isArray(file)) {
        return file.map((photo, index) => (
          <Box
            key={`${photo.filename}-${index}`}
            display="inline-flex"
            height={130}
            width={180}
            borderRadius={1}
            marginRight={1}
            marginBottom={1}
            flexShrink={0}
            sx={{
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minWidth={0}
              borderRadius={"3px"}
              overflow="hidden"
              position="relative"
              height="100%"
              width="100%"
            >
              <img
                src={
                  photo.fileUrl
                    ? photo.fileUrl
                    : `${process.env.REACT_APP_URL_API}/image/${photo.filename}`
                }
                alt={photo.filename}
                height="100%"
                width="100%"
                style={{ display: "block", objectFit: "cover" }}
              />
              <Box
                position="absolute"
                height={130}
                width={180}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: "rgba(255, 255, 255, 0.5)",
                  opacity: 0,
                  transition: "opacity 0.2s linear",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (file.fileUrl) {
                      URL.revokeObjectURL(file.fileUrl);
                    }
                    const newFiles = file.filter((item, i) => i !== index);
                    setValue(name, newFiles);
                  }}
                >
                  <DeleteForeverOutlined color="error" fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ));
      } else {
        return (
          <Box
            key={`${file.filename}-${Math.random()}`}
            display="inline-flex"
            height={130}
            width={180}
            borderRadius={1}
            marginRight={1}
            marginBottom={1}
            flexShrink={0}
            sx={{
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minWidth={0}
              borderRadius={"3px"}
              overflow="hidden"
              position="relative"
              height="100%"
              width="100%"
            >
              <img
                src={
                  file.fileUrl
                    ? file.fileUrl
                    : `${process.env.REACT_APP_URL_API}/image/${file.filename}`
                }
                alt={file.filename}
                height="100%"
                width="100%"
                style={{ display: "block", objectFit: "cover" }}
              />
              <Box
                position="absolute"
                height={130}
                width={180}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: "rgba(255, 255, 255, 0.5)",
                  opacity: 0,
                  transition: "opacity 0.2s linear",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (file.fileUrl) {
                      URL.revokeObjectURL(file.fileUrl);
                    }
                    setValue(name, null);
                  }}
                >
                  <DeleteForeverOutlined color="error" fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        );
      }
    }
    return null;
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={isMultiple ? [] : null}
      render={({ fieldState: { error } }) => (
        <>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            flexShrink={0}
            overflow="auto"
            sx={{
              "&::-webkit-scrollbar": {
                width: 5,
                height: 5,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.15)",
                borderRadius: (theme) => theme.shape.borderRadius,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.25)",
              },
            }}
          >
            <>{generateThumbs()}</>
            {(!file && !isMultiple) || isMultiple ? (
              <Box
                {...getRootProps()}
                display="flex"
                height={130}
                width={180}
                borderRadius={1}
                flexShrink={0}
                alignItems="center"
                justifyContent="center"
                sx={{
                  border: (theme) =>
                    `1px solid ${
                      error
                        ? theme.palette.error.main
                        : theme.palette.primary.main
                    }`,
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                <AddOutlined
                  color={error ? "error" : "primary"}
                  fontSize="large"
                />
              </Box>
            ) : null}
          </Box>
          <FormHelperText error={!!error} sx={{ ml: 1.75 }}>
            {error?.message}
          </FormHelperText>
        </>
      )}
    />
  );
};

export default UploadImage;
