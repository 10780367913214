import api from "../common/api";

const getAll = () => api.get("/order");

const get = (id) => api.get(`/order/${id}`);

const create = (data) => api.post("/order", data);

const update = (data) => api.patch(`/order/${data.id}/${data.status}`);

const refuseCancellation = (id) => api.patch(`/order/refuse/${id}`);

const cancelOrder = (id) => api.patch(`/order/cancel/${id}`);

const dashboardInfo = () => api.get(`/order/dashboard-info`);

const uploadNfes = (id, data) => api.post(`/order/nfes/${id}`, data);

const orderService = {
  getAll,
  get,
  create,
  update,
  refuseCancellation,
  cancelOrder,
  dashboardInfo,
  uploadNfes,
};

export default orderService;
